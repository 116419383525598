import * as yup from 'yup'
import {
  emailRegex,
  isAddressIncomplete,
  isAllowedProvince,
  isAddressPOBox,
  isPhoneNumberValid,
  isPostalCodeValid,
  isPostalCodeProvinceValid
} from 'utils/validators'
import { ADRESS_ERROR_MESSAGE } from 'utils/constants'

export const getInitialValues = user => {
  return {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    address: user.address || '',
    province: user.province || '',
    city: user.city || '',
    country: user.country || 'Canada',
    postalCode: user.postalCode || '',
    email: user.email || '',
    phoneNumber: user.phoneNumber || '',
    gender: user.gender || ''
  }
}

export const validationSchema = user => {
  return yup.lazy(values => {
    let schema = {
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup
        .string()
        .email('Email address is invalid')
        .required('Email is required')
        .matches(emailRegex, 'Email address is invalid'),
      phoneNumber: yup
        .string()
        .test('phoneNumber', 'Phone number is invalid', () => isPhoneNumberValid(values.phoneNumber)),
      gender: yup.string()
    }

    if (user.address) {
      // Full Address enterred manually
      if (values.manualEntry) {
        schema = {
          ...schema,
          postalCode: yup
            .string()
            .required('Postal code is required')
            .test('postalCode', 'Invalid postal code', isPostalCodeValid())
            .test('postalCode', ADRESS_ERROR_MESSAGE, isPostalCodeProvinceValid()),
          address: yup
            .string()
            .required('Address is required')
            .test(
              'address',
              'For legal reasons, we cannot ship to PO boxes, please select a residential address.',
              () => !isAddressPOBox(values)
            )
            .test('address', ADRESS_ERROR_MESSAGE, isAllowedProvince(values.province))
        }
      } else {
        // Full Address enterred with AddressComplete from Canadapost
        schema = {
          ...schema,
          address: yup
            .string()
            .required('Address is required')
            .test(
              'address',
              'For legal reasons, we cannot ship to PO boxes, please select a residential address.',
              () => !isAddressPOBox(values)
            )
            .test('address', ADRESS_ERROR_MESSAGE, isAllowedProvince(values.province))
            .test('address', 'You must select an address in the suggested list', isAddressIncomplete(values))
        }
      }
    } else {
      schema = {
        ...schema,
        postalCode: yup
          .string()
          .required('Postal code is required')
          .test('postalCode', 'Invalid postal code', isPostalCodeValid())
          .test('postalCode', ADRESS_ERROR_MESSAGE, isPostalCodeProvinceValid())
      }
    }

    return yup.object().shape(schema)
  })
}
