import React from 'react'
import { string } from 'prop-types'
import styled, { keyframes } from 'styled-components'
import * as spacing from 'styles/spacing'
import Icon from 'components/Icon'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerIcon = styled(Icon)`
  animation: ${rotate} 0.75s linear infinite;
  position: absolute;
  margin-left: -12px;
  margin-top: -12px;
  top: 50%;

  ${props => props.position === 'center' && `left: 50%;`}
  ${props => props.position === 'right' && `right: ${spacing.medium};`}
`
const Spinner = ({ position }) => <SpinnerIcon icon="spinner" position={position} />

Spinner.propTypes = {
  position: string
}

export default Spinner
