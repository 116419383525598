import React from 'react'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import { H4 } from 'styles/typography'

export default () => (
  <Section>
    <Wrapper>
      <H4>The page you're looking for does not exist.</H4>
    </Wrapper>
  </Section>
)
