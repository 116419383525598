import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { node, string } from 'prop-types'

const Portal = ({ selector, children }) => {
  const modalRoot = document.getElementById(selector)
  const el = document.createElement('div')

  useEffect(() => {
    modalRoot.appendChild(el)
    return () => {
      modalRoot.removeChild(el)
    }
  }, [el, modalRoot])

  return ReactDOM.createPortal(children, el)
}

Portal.propTypes = {
  selector: string.isRequired,
  children: node.isRequired
}

export default Portal
