import React, { useEffect, useState } from 'react'
import { array, func, object } from 'prop-types'
import { useFormik, FormikProvider } from 'formik'
import * as yup from 'yup'
import { useLocalForage } from 'contexts/LocalForage'

import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import ButtonCta from 'components/Button/ButtonCta'
import Dropdown from 'components/FormComponents/Dropdown'
import DropdownWithSearch from '../FormComponents/DropdownWithSearch'
import Toggle from 'components/FormComponents/Toggle'
import AgentSourcesTip from 'components/AgentLogin/AgentSourcesTip'
import { CANADIAN_PROVINCES } from 'utils/constants'

const AgentSurveyForm = ({ fillAgentInfo, agentErpSource, agentSources }) => {
  // taking agentErpSource and agentSources from context since immediate change to local storage wont reflect in the same component
  const { getStoredItem } = useLocalForage()
  const [agentSourcesOptions, setAgentSourcesOptions] = useState({})

  const validationSchema = yup.lazy(() =>
    yup.object().shape({
      agentSource: yup.string().required('Location is required'),
      agentErpSource: isERPPresent() ? yup.string().required('ERP location is required') : '',
      darkMarket: yup.bool(),
      agentProvinceActivation: yup.string().required('Province is required')
    })
  )

  const formik = useFormik({
    initialValues: {
      agentSource: '',
      agentErpSource: '',
      darkMarket: false,
      agentProvinceActivation: ''
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      await fillAgentInfo(values)
    }
  })
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = formik

  useEffect(() => {
    const fetchAgentSources = async () => {
      let agentSourceOptions = {}
      if (Object(agentSources).keys?.length > 0) agentSourceOptions = agentSources
      else agentSourceOptions = await getStoredItem('agentSourceOptions')
      // if context doesnot have data will fetch from local storage
      setAgentSourcesOptions(agentSourceOptions)
    }
    fetchAgentSources()
  }, [agentSources])

  const handleSourceChange = e => {
    handleChange(e)
  }

  const isERPPresent = () => agentErpSource?.length > 0

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <FieldsetItem xs="1-1">
            <Dropdown
              label="Where are you activating?"
              name="agentSource"
              value={values.agentSource}
              options={agentSourcesOptions}
              onChange={handleSourceChange}
              onBlur={handleBlur}
              placeholder="Choose the most accurate location"
              full
              tip={<AgentSourcesTip />}
              meta={{
                error: errors.agentSource,
                touched: touched.agentSource
              }}
            />
          </FieldsetItem>
        </Fieldset>

        {isERPPresent() && (
          <Fieldset>
            <FieldsetItem xs="1-1">
              <DropdownWithSearch
                label="ERP?"
                name="agentErpSource"
                value={values.agentErpSource}
                options={agentErpSource}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Choose the most accurate location"
                full
                meta={{
                  error: errors.agentErpSource,
                  touched: touched.agentErpSource
                }}
                setFieldValue={setFieldValue}
                setTouched={setTouched}
              />
            </FieldsetItem>
          </Fieldset>
        )}

        <Fieldset>
          <FieldsetItem xs="1-1">
            <Dropdown
              label="In which province are you?"
              name="agentProvinceActivation"
              value={values.agentProvinceActivation}
              options={CANADIAN_PROVINCES}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Choose your current province"
              full
              meta={{
                error: errors.agentProvinceActivation,
                touched: touched.agentProvinceActivation
              }}
            />
          </FieldsetItem>
        </Fieldset>

        <Fieldset>
          <FieldsetItem xs="1-1">
            <Toggle
              label="Dark market"
              name="darkMarket"
              value={values.darkMarket}
              onChange={handleChange}
              onBlur={handleBlur}
              meta={{
                error: errors.darkMarket,
                touched: touched.darkMarket
              }}
            />
          </FieldsetItem>
        </Fieldset>

        <Fieldset>
          <FieldsetItem xs="1-1">
            <ButtonCta
              type="submit"
              disabled={
                values.agentSource === '' ||
                values.agentProvinceActivation === '' ||
                (isERPPresent() && values.agentErpSource === '')
              }
              isLoading={isSubmitting && !errors}
            >
              Log In
            </ButtonCta>
          </FieldsetItem>
        </Fieldset>
      </form>
    </FormikProvider>
  )
}

AgentSurveyForm.propTypes = {
  fillAgentInfo: func.isRequired,
  agentErpSource: array.isRequired,
  agentSources: object.isRequired
}

export default AgentSurveyForm
