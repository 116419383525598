export const white = '#fff'
export const black = '#000'

/**
 * New colors
 */
// Brand colors
const vuseYellow = '#F8AB28'
const vuseBlack = '#1d1d1b'
const charcoal = '#2a2a2a'
const midGrey = '#969696'
const concrete = '#dedede'
const grey = '#d1d1d1'
export const greyLight = '#999'
const darkGrey = '#636363'
const paleGrey = '#f1f1f1'
const lightGrey = '#F6F6F6'
const vuseRed = '#991F39'
const vuseGreen = '#04695D'

// Background
export const backgroundColor = vuseBlack

// Text
export const textColor = charcoal
export const disabledTextColor = '#B0B0B0'
export const placeholderColor = darkGrey

// Input
export const inputBackColor = lightGrey
export const disabledInputBackColor = concrete
export const hoverInputColor = concrete

// Toggle
export const toggleFalseColor = midGrey
export const toggleTrueColor = charcoal
export const disabledToggleColor = concrete

// Buttons
export const buttonBackColor = vuseYellow
export const disabledButtonBackColor = paleGrey

// Border
export const borderColorDarker = vuseBlack
export const borderColor = charcoal
export const lightBorderColor = paleGrey
export const separatorColor = grey

// Infos
export const errorColor = vuseRed
export const successColor = vuseGreen
