import React, { useState } from 'react'
import Layout from 'layouts/Layout'
import { Route, Routes, Navigate } from 'react-router-dom'

import { useLocalForage } from 'contexts/LocalForage'

import Error from 'components/Error'
import AgentLogin from 'pages/AgentLogin'
// import Home from 'pages/Home'
import VuseAccountCreation from 'pages/VuseAccountCreation'
import NotFound from 'pages/NotFound'

export default () => {
  const [error, setError] = useState(null)
  const { localForageError, setLocalForageError } = useLocalForage()

  return (
    <>
      <Layout>
        {error || localForageError ? (
          <Error resetError={error ? setError : setLocalForageError} />
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/vuse-account-creation" />} />
            <Route path="/login" element={<AgentLogin />} />
            <Route path="/vuse-account-creation" element={<VuseAccountCreation />} />
            <Route element={<NotFound />} />
          </Routes>
        )}
      </Layout>
    </>
  )
}
