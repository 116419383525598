import styled, { css } from 'styled-components'

import { media } from './media'
import { textColor, placeholderColor } from './colors'

// Font family
export const fontFamily =
  "GothamBook, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"

// Font weights
export const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 600,
  bold: 700,
  heavy: 900
}

export const lineHeights = {
  base: 1.5,
  heading: 1.25,
  tight: 1.15
}

// Font sizes
export const fontSizes = {
  xxLarge: '32px',
  xLarge: '28px',
  large: '22px',
  regular: '16px',
  small: '14px',
  tiny: '12px'
}

export const H1 = styled.h1`
  font-weight: ${fontWeights.heavy};
  line-height: ${lineHeights.heading};
  font-size: ${fontSizes.large};
  font-family: ${fontFamily};
  margin-bottom: 0.2em;
  text-transform: uppercase;
  ${media.small`font-size:  ${fontSizes.xLarge}`};
  ${media.medium`font-size: ${fontSizes.xxLarge}`};
`

export const H2 = styled.h2`
  font-weight: ${fontWeights.heavy};
  line-height: ${lineHeights.heading};
  font-size: 1.6rem;
  font-family: ${fontFamily};
  margin-bottom: 0.4em;
  ${media.small`font-size: 1.75rem`};
  ${media.medium`font-size: 2rem`};
  ${media.large`font-size: 2.25rem`};
`

export const H3 = styled.h3`
  font-weight: ${fontWeights.heavy};
  line-height: ${lineHeights.heading};
  font-size: 1.3rem;
  font-family: ${fontFamily};
  margin-bottom: 0.5em;
  ${media.small`font-size: 1.4rem`};
  ${media.medium`font-size: 1.55rem`};
  ${media.large`font-size: 1.7rem`};
`

export const H4 = styled.h4`
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeights.heading};
  font-size: 1.1rem;
  font-family: ${fontFamily};
  margin-bottom: 0.5em;
  ${media.small`font-size: 1.2rem`};
  ${media.medium`font-size: 1.25rem`};
  ${media.large`font-size: 1.3rem`};
`

export const H5 = styled.h5`
  font-weight: ${fontWeights.bold};
  font-size: 1em;
  line-height: ${lineHeights.heading};
`

export const Lead = styled.p`
  font-size: 1.1em;

  ${media.small`
    font-size: 1.15em;
  `};

  ${media.medium`
    font-size: 1.2em;
  `};

  ${media.large`
    font-size: 1.25em;
  `};
`

export const Underline = styled.span`
  text-decoration: underline;
`

export default css`
  body {
    color: ${textColor};
    font-family: ${fontFamily};
    font-weight: ${fontWeights.regular};
    line-height: ${lineHeights.base};
    -webkit-font-smoothing: subpixel-antialiased;
    font-size: ${fontSizes.small};

    ${media.medium`
      font-size: ${fontSizes.regular};
    `};
  }

  small {
    font-size: ${fontSizes.small};
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  p {
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.medium`
      margin-bottom: 1.5em;
    `};
  }

  p a {
    border-bottom: 1px solid ${placeholderColor};
    transition: border-color 0.3s ease-in-out;

    &:hover,
    &:focus {
      border-color: ${placeholderColor};
    }
  }
`
