import React from 'react'
import styled from 'styled-components'
import { node } from 'prop-types'

import { GlobalStyle } from 'styles/global'
import Header from 'components/Header'
import Portal from 'components/Portal'
import Modal from 'components/Modal'

const Content = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 48px;
`

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Header />

    <Content>
      <div>{children}</div>
    </Content>

    <Portal selector="modals">
      <Modal />
    </Portal>
  </>
)

Layout.propTypes = {
  children: node.isRequired
}

export default Layout
