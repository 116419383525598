import React from 'react'
import { bool, node } from 'prop-types'
import styled from 'styled-components'
import { stripUnit } from 'polished'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 740px;
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${media.small`
    padding-left: ${spacing.xxLarge};
    padding-right: ${spacing.xxLarge};
  `};

  ${media.medium`
    padding-left: ${stripUnit(spacing.xLarge) * 1.5 + 'px'};
    padding-right: ${stripUnit(spacing.xLarge) * 1.5 + 'px'};
  `};

  ${props =>
    props.center &&
    `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`

const Wrapper = ({ children, center }) => <Container center={center}>{children}</Container>

Wrapper.defaultProps = {
  center: false
}

Wrapper.propTypes = {
  children: node.isRequired,
  center: bool
}

export default Wrapper
