import React from 'react'
import AutoComplete from 'components/FormComponents/AutoComplete'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'

import { useFormikContext } from 'formik'
import { useLocalForage } from '../../../contexts/LocalForage'

const PreferredBrand = () => {
  const { setFieldValue } = useFormikContext()
  const { getStoredItem } = useLocalForage()

  const filterPreferredBrandTypeSuggestions = async (term, brandOptions) => {
    const suggestions = []
    let filteredEntries = Object.entries(brandOptions)
    if (typeof term !== 'undefined' && term.length > 0) {
      filteredEntries = Object.entries(brandOptions).filter(([key]) => key.toLowerCase().includes(term.toLowerCase()))
    }

    const filteredObj = Object.fromEntries(filteredEntries)
    for (const brand in filteredObj) {
      suggestions.push({ value: filteredObj[brand], label: `${brand}` })
    }

    return suggestions
  }

  const onSelectVape = brandVapeSelected => {
    setFieldValue('preferredBrandVapeValue', brandVapeSelected.value)
  }

  const onSelectCigarette = brandCigaretteSelected => {
    setFieldValue('preferredBrandCigaretteValue', brandCigaretteSelected.value)
  }

  const onVapeChange = e => {
    setFieldValue('preferredBrandVapeValue', '')
  }

  const onCigaretteChange = e => {
    setFieldValue('preferredBrandCigaretteValue', '')
  }

  const filterVapeSuggestions = async term => {
    const preferredBrandVapeOptions = await getStoredItem('preferredBrandVape')
    const filteredSuggestions = await filterPreferredBrandTypeSuggestions(term, preferredBrandVapeOptions)
    return filteredSuggestions
  }

  const filterCigaretteSuggestions = async term => {
    const preferredBrandCigaretteOptions = await getStoredItem('preferredBrandCigarette')
    const filteredSuggestions = filterPreferredBrandTypeSuggestions(term, preferredBrandCigaretteOptions)
    return filteredSuggestions
  }

  return (
    <div>
      <Fieldset>
        <FieldsetItem xs="1-1">
          <AutoComplete
            filterSuggestions={filterVapeSuggestions}
            onChange={onVapeChange}
            onSelect={onSelectVape}
            label="What is the consumer’s preferred Vape brand?"
            optional={2}
            placeholder="Select or type the preferred vape brand"
            dropdown
          />
        </FieldsetItem>
      </Fieldset>
      <Fieldset>
        <FieldsetItem xs="1-1">
          <AutoComplete
            filterSuggestions={filterCigaretteSuggestions}
            onChange={onCigaretteChange}
            onSelect={onSelectCigarette}
            label="What is the consumer’s preferred Cigarette Brand?"
            optional={2}
            placeholder="Select or type the preferred cigarette brand"
            dropdown
          />
        </FieldsetItem>
      </Fieldset>
    </div>
  )
}

export default PreferredBrand
