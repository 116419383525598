import React from 'react'
import { arrayOf, func, number, shape, string } from 'prop-types'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { stripUnit } from 'polished'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontWeights } from 'styles/typography'
import { useOptionsContext } from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/OptionsContext'
import Icon from 'components/Icon'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import Dropdown from 'components/FormComponents/Dropdown'

const FlavourContainer = styled.div`
  width: 100%;
`

const AddFlavourBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 3px;
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};
  padding: ${spacing.mini};

  ${media.medium`
    padding: ${stripUnit(spacing.mini) * 1.5 + 'px'};
  `};
`

const RemoveButtonContainer = styled.div`
  position: relative;
`

const RemoveButton = styled.button`
  position: absolute;
  right: -2em;
  top: 50%;
  transform: translateY(-50%);
`

const Flavour = ({ bundle, flavour, name, indexFlavour, pushFlavour, removeFlavour }) => {
  const { handleChange, handleBlur } = useFormikContext()
  const { flavourOptions } = useOptionsContext()
  const { options, max } = flavourOptions[bundle.deviceId] || { options: {}, max: -1 }

  return (
    <FlavourContainer key={indexFlavour}>
      <FieldsetItem xs="1-1">
        <RemoveButtonContainer>
          <Dropdown
            label={indexFlavour === 0 && 'What flavour did we offer the consumer?'}
            name={name}
            value={flavour}
            options={options}
            disabled={!bundle.deviceId}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Select Flavour"
            optional={2}
            full
          />
          {indexFlavour > 0 && (
            <RemoveButton type="button" onClick={() => removeFlavour(indexFlavour)}>
              <Icon icon="cross-red" customSize="22px" />
            </RemoveButton>
          )}
        </RemoveButtonContainer>
      </FieldsetItem>

      {(max < 0 || indexFlavour + 1 < max) && indexFlavour === bundle.flavourIds.length - 1 && flavour && (
        <AddFlavourBtn type="button" onClick={() => pushFlavour('')}>
          <Icon icon="plus" customSize="22px" /> Add another flavour
        </AddFlavourBtn>
      )}
    </FlavourContainer>
  )
}

Flavour.propTypes = {
  bundle: shape({ deviceId: string, flavourIds: arrayOf(string) }).isRequired,
  flavour: string,
  name: string.isRequired,
  indexFlavour: number.isRequired,
  pushFlavour: func.isRequired,
  removeFlavour: func.isRequired
}

export default Flavour
