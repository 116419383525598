export const formatDropdownOptions = array => {
  return array.reduce((allOptions, option) => {
    return { ...allOptions, [option.label]: option.value }
  }, {})
}

export const moveToEnd = (array, key) => {
  const subArrFromKey = array.find(subArr => subArr.includes(key))
  if (subArrFromKey) {
    const index = array.findIndex(subArr => subArr[0] === subArrFromKey[0] && subArr[1] === subArrFromKey[1])
    if (index !== -1) {
      array.splice(index, 1)
      array.push(subArrFromKey)
    }
  }
  return array
}

export const numberWithComma = number => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
