import React, { createContext, useContext, useState, useEffect } from 'react'
import { node } from 'prop-types'
import { useLocalForage } from 'contexts/LocalForage'

const OptionsContextContext = createContext()

export const useOptionsContext = () => {
  return useContext(OptionsContextContext)
}

export const OptionsContextProvider = ({ children }) => {
  const [deviceOptions, setDeviceOptions] = useState({})
  const [flavourOptions, setFlavourOptions] = useState({})
  const { getStoredItem } = useLocalForage()

  useEffect(() => {
    const fetchData = async () => {
      const deviceGivenOptions = await getStoredItem('deviceGivenOptions')
      const flavoursGivenOptions = await getStoredItem('flavoursGivenOptions')

      setDeviceOptions(deviceGivenOptions)
      setFlavourOptions(flavoursGivenOptions)
    }

    fetchData()
  }, [])

  const value = { deviceOptions, flavourOptions }

  return <OptionsContextContext.Provider value={value}>{children}</OptionsContextContext.Provider>
}

export const OptionsContextConsumer = OptionsContextContext.Consumer

OptionsContextProvider.propTypes = {
  children: node.isRequired
}
