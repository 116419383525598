import React, { useEffect, useState } from 'react'
import { string } from 'prop-types'
import { useFormikContext, FieldArray } from 'formik'
import styled from 'styled-components'
import * as spacing from 'styles/spacing'
import Button from 'components/Button'
import Bundle, { getBundle } from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/Bundle'
import { OptionsContextProvider } from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/OptionsContext'
import { bundleInitShape } from 'components/AccountCreation/CreationFormThirdStep/formValidation'

const ButtonContainer = styled.div`
  width: 45%;
  margin: ${spacing.xxLarge} auto;
`

const BundlesForm = ({ name }) => {
  const [isOneBundleComplete, setIsOneBundleComplete] = useState(false)
  const { values } = useFormikContext()

  useEffect(() => {
    const bundleCompleted = values.bundlesGiven.find(bundle => {
      const bundleSelected = getBundle(bundle)
      const isBundleComplete = bundleSelected?.deviceId && bundleSelected?.flavourIds.length > 0
      return isBundleComplete
    })
    if (bundleCompleted) setIsOneBundleComplete(bundleCompleted)
  }, [values.bundlesGiven])

  return (
    <OptionsContextProvider>
      <FieldArray name={name}>
        {({ remove, push }) => (
          <>
            {values.bundlesGiven.length > 0 &&
              values.bundlesGiven.map((bundle, index) => (
                <Bundle
                  key={index}
                  bundle={bundle}
                  name={`bundlesGiven.${index}`}
                  indexBundle={index}
                  removeBundle={remove}
                />
              ))}

            {isOneBundleComplete && (
              <ButtonContainer>
                <Button type="button" theme="primary" fullwidth onClick={() => push(bundleInitShape)}>
                  Add a device
                </Button>
              </ButtonContainer>
            )}
          </>
        )}
      </FieldArray>
    </OptionsContextProvider>
  )
}

BundlesForm.propTypes = {
  name: string.isRequired
}

export default BundlesForm
