import { InMemoryCache } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'

import localforage from 'lib/localforage'

const cache = new InMemoryCache()

export const waitOnCache = persistCache({
  cache,
  storage: localforage
})
