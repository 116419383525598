import React from 'react'
import { bool, node, number, string } from 'prop-types'
import styled, { css } from 'styled-components'
import classnames from 'classnames'
import { stripUnit } from 'polished'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { animationTime, animationCurve } from 'styles/global'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: ${spacing.mini};
  opacity: ${props => props.opacity};
  transition: opacity ${animationTime} ${animationCurve};

  & > :first-child {
    flex-grow: 1;
  }

  ${props =>
    props.noPaddingTop &&
    css`
      padding-top: 0;
    `}

  ${props =>
    props.noPaddingBottom &&
    css`
      padding-bottom: 0;
    `}

  ${media.medium`
    padding: ${stripUnit(spacing.mini) * 1.5 + 'px'};

    ${props =>
      props.noPaddingTop &&
      css`
        padding-top: 0;
      `}

    ${props =>
      props.noPaddingBottom &&
      css`
        padding-bottom: 0;
      `}
  `};
`

const Child = styled.div`
  flex-grow: 1;
`

const FieldsetItem = ({ xs, sm, md, lg, xlg, children, className, noPaddingTop, noPaddingBottom, opacity = 1 }) => (
  <Container
    noPaddingTop={noPaddingTop}
    noPaddingBottom={noPaddingBottom}
    opacity={opacity}
    className={classnames(className, {
      [`u-${xs}`]: xs,
      [`u-${sm}-sm`]: sm,
      [`u-${md}-md`]: md,
      [`u-${lg}-lg`]: lg,
      [`u-${xlg}-lg`]: xlg
    })}
  >
    <Child>{children}</Child>
  </Container>
)

FieldsetItem.propTypes = {
  children: node.isRequired,
  className: string,
  noPaddingTop: bool,
  noPaddingBottom: bool,
  xs: string,
  sm: string,
  md: string,
  lg: string,
  xlg: string,
  opacity: number
}

export default FieldsetItem
