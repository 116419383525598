import localforage from 'lib/localforage'

const apiRequest = async ({ endpoint, headers = {}, ...rest }) => {
  const token = await localforage.getItem('agentJWT')

  const response = await fetch(`${process.env.AUTH_API}${endpoint}`, {
    headers: { 'Content-Type': 'application/json', Authorization: token },
    ...rest
  })

  if (!response.ok) {
    const error = {
      status: response.status,
      statusText: response.statusText
    }

    throw error
  }

  return response.json()
}

export const api = {
  get: params => apiRequest({ method: 'GET', ...params }),
  post: ({ body, ...rest }) => apiRequest({ method: 'POST', body: JSON.stringify(body), ...rest }),
  put: ({ body, ...rest }) => apiRequest({ method: 'PUT', body: JSON.stringify(body), ...rest }),
  delete: params => apiRequest({ method: 'DELETE', ...params })
}
