import { css, createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'

import Normalize from './normalize'
import Width from './width'
import Push from './push'
import Utilities from './utilities'
import Slick from './slick'
import Animate from './animate'
import Typography from './typography'
import { black, white } from './colors'

// Animation
export const animationTime = '0.3s'
export const animationCurve = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)'

// Border Radius
export const borderRadius = '100px'

// Inputs
export const inputHeight = '48px'

// Vertical padding
export const verticalPadding = padding => css`
  padding-top: ${padding};
  padding-bottom: ${padding};
`

// Square
export const square = size => css`
  width: ${size};
  height: ${size};
`

export const boxShadow = `box-shadow: 0 2px 4px 0 ${rgba(black, 0.05)};`
export const boxShadowDark = `box-shadow: 0 3px 6px 0 ${rgba(black, 0.1)};`

// Cover
export function cover(position) {
  return `
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}

// Styles

export const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Width};
  ${Push};
  ${Utilities};
  ${Typography};
  ${Slick};
  ${Animate};

  body {
    background-color: ${white};

    &.has-modal,
    &.is-clipped {
      position: fixed !important;
      overflow: hidden !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
`
