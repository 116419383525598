import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { black } from 'styles/colors'
import { animationTime, animationCurve } from 'styles/global'

import { useModalContext } from 'contexts/Modal'

const ModalBackDrop = styled.div`
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${rgba(black, 0.75)};
  transition: opacity ${animationTime} ${animationCurve}, visibility ${animationTime} ${animationCurve};
  opacity: ${props => (props.visible && 1) || 0};
  visibility: ${props => (props.visible && 'visible') || 'hidden'};
  z-index: 900;
  -webkit-overflow-scrolling: ${props => (props.visible && 'touch') || 'auto'};
`

const Modal = () => {
  const [{ isModalOpen, content }] = useModalContext()

  return <ModalBackDrop visible={isModalOpen}>{content}</ModalBackDrop>
}

export default Modal
