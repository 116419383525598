import React from 'react'
import styled from 'styled-components'
import { bool, string } from 'prop-types'
import { Field } from 'formik'
import { fontWeights } from 'styles/typography'
import { toggleFalseColor, toggleTrueColor } from 'styles/colors'
import Icon from 'components/Icon'

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const SliderContainer = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${toggleFalseColor};
  transition: 0.4s;
  border-radius: 40px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    z-index: 2;
    border-radius: 50%;
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;

  input:checked + ${SliderContainer} {
    background-color: ${toggleTrueColor};
  }

  input:checked + ${SliderContainer}:before {
    transform: translateX(20px);
  }
`

const SliderContent = styled.span`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`

const CrossIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-75%, -50%);
  z-index: 1;
`

const Label = styled.p`
  font-weight: ${fontWeights.semibold};
`

const Toggle = ({ name, value, label }) => (
  <Container>
    <Switch>
      <Field name={name} value={value} type="checkbox" checked={value} />
      <SliderContainer>
        <SliderContent>
          <CrossIcon icon="cross-white" customSize={'12px'} />
        </SliderContent>
      </SliderContainer>
    </Switch>

    {label && <Label>{label}</Label>}
  </Container>
)

Toggle.propTypes = {
  name: string.isRequired,
  value: bool.isRequired,
  label: string
}

export default Toggle
