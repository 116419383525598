import { css } from 'styled-components'
import { errorColor, disabledTextColor, disabledInputBackColor, borderColor, inputBackColor } from 'styles/colors'
import { animationTime, animationCurve, inputHeight } from 'styles/global'
import * as spacing from 'styles/spacing'

export const field = css`
  height: ${inputHeight};
  border-bottom: 1px solid ${borderColor};
  background-color: ${inputBackColor};
  width: 100%;
  resize: none;
  vertical-align: top;
  outline: 0;
  padding: 0 ${spacing.medium};
  transition: all ${animationTime} ${animationCurve};

  ${props => props.error && `border-bottom: 1px solid ${errorColor};`}

  &[disabled] {
    color: ${disabledTextColor};
    pointer-events: none;
    background-color: ${disabledInputBackColor};
    user-select: none;
    opacity: 1; /* Overwrite iOS styles */
  }
`
