import React from 'react'
import Back from './Back'
import CreationFormFirstStep from './CreationFormFirstStep'
import CreationFormSecondStep from './CreationFormSecondStep'
import CreationFormThirdStep from './CreationFormThirdStep'
import ReturningConsumerFirstStep from 'components/ReturningConsumer/ReturningConsumerFirstStep'
import ReturningConsumerSecondStep from 'components/ReturningConsumer/ReturningConsumerSecondStep'
import { AccountCreationProvider, AccountCreationConsumer } from 'contexts/AccountCreation'
import { Toaster } from 'react-hot-toast'

const AccountCreation = () => {
  const renderStep = (step, flow) => {
    if (step === 1) {
      return <CreationFormFirstStep />
    }

    if (step === 2) {
      return flow === 'ReturningConsumer' ? <ReturningConsumerFirstStep /> : <CreationFormSecondStep />
    }

    if (step === 3) {
      return flow === 'ReturningConsumer' ? <ReturningConsumerSecondStep /> : <CreationFormThirdStep />
    }

    return <></>
  }

  return (
    <AccountCreationProvider>
      <AccountCreationConsumer>
        {({ step, previousStep, flow }) => (
          <>
            {step > 1 && <Back onClick={previousStep} />}

            {renderStep(step, flow)}
            <Toaster position="bottom-right" reverseOrder={false} />
          </>
        )}
      </AccountCreationConsumer>
    </AccountCreationProvider>
  )
}

export default AccountCreation
