import React, { createContext, useContext, useMemo, useReducer } from 'react'
import { node } from 'prop-types'

import { modalReducer, initialState } from './reducer'

const ModalContext = createContext(initialState)

const useModalContext = () => useContext(ModalContext)

const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState)

  const actions = useMemo(() => [state, dispatch], [state])

  return <ModalContext.Provider value={actions}>{children}</ModalContext.Provider>
}

ModalContextProvider.propTypes = {
  children: node.isRequired
}

export { useModalContext, ModalContextProvider }
