import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'

const Container = styled.div`
  width: 80%;
  margin: auto;
`

const ButtonCta = ({ children, ...rest }) => (
  <Container>
    <Button theme="primary" fullwidth {...rest}>
      {children}
    </Button>
  </Container>
)

ButtonCta.propTypes = {
  children: node.isRequired
}

export default ButtonCta
