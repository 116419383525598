import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SessionContext } from 'contexts/Session'
import { useLocalForage } from 'contexts/LocalForage'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import AgentLoginForm from 'components/AgentLogin/AgentLoginForm'
import AgentSurveyForm from 'components/AgentLogin/AgentSurveyForm'

const AgentLogin = () => {
  const { login, loggedIn, agentErpSource, agentSources } = useContext(SessionContext)
  const { getStoredItem, setStoredItem } = useLocalForage()
  const navigate = useNavigate()

  const loginAgent = async values => {
    await login(values)
  }

  const fillAgentInfo = async values => {
    setStoredItem('agentSource', values.agentSource)
    setStoredItem('darkMarket', values.darkMarket)
    setStoredItem('agentErpSource', values.agentErpSource)
    const agentInformation = await getStoredItem('agentInformation')

    const updatedAgentInformation = {
      ...agentInformation,
      agentProvinceActivation: values.agentProvinceActivation
    }

    setStoredItem('agentInformation', updatedAgentInformation)
    navigate('/vuse-account-creation')
  }

  return (
    <Section title={'Agent login Try'}>
      <Wrapper>
        <AgentLoginForm loginAgent={loginAgent} />
        {loggedIn && (
          <AgentSurveyForm fillAgentInfo={fillAgentInfo} agentErpSource={agentErpSource} agentSources={agentSources} />
        )}
      </Wrapper>
    </Section>
  )
}

export default AgentLogin
