import React from 'react'
import styled from 'styled-components'
import { media } from 'styles/media'
import { square } from 'styles/global'
import * as spacing from 'styles/spacing'
import Icon from 'components/Icon'

const Container = styled.div`
  text-align: center;

  width: 100%;
  ${media.medium`width: 835px;`};
`

const PerksList = styled.div`
  display: flex;
  gap: ${spacing.small};
  flex-direction: column;
  margin: ${spacing.large} 0;

  ${media.small`
    margin: ${spacing.xxLarge} 0;
    flex-direction: row;
  `}

  ${media.medium`
    gap: ${spacing.large};
  `}
`

const Perk = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;

  gap: ${spacing.small};
  ${media.medium`gap: ${spacing.large}`};
`

const PerkIcon = styled(Icon)`
  flex-shrink: 0;

  ${square`46px`};
  ${media.medium`${square`56px`}`};
`

const VuseRewardsPerks = () => (
  <Container>
    <p>Join Vuse Rewards and opt-in to Vuse Marketing communications to get your offer from our Brand Ambassador.</p>

    <PerksList>
      <Perk>
        <PerkIcon icon="vuse-rewards/gift" />
        <p>
          <strong>Get first & exclusive</strong> access to products.
        </p>
      </Perk>

      <Perk>
        <PerkIcon icon="vuse-rewards/discount" />
        <p>
          <strong>Save more </strong> with special offers & discounts.
        </p>
      </Perk>

      <Perk>
        <PerkIcon icon="vuse-rewards/points" />
        <p>
          <strong>Shop, engage & earn points</strong> - It's that easy!
        </p>
      </Perk>
    </PerksList>
  </Container>
)

export default VuseRewardsPerks
