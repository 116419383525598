import React, { useContext, useEffect, useRef } from 'react'
import { func, object } from 'prop-types'
import { Form, Formik } from 'formik'
import { SessionContext } from 'contexts/Session'
import { MODAL_ACTIONS, useModalContext } from 'contexts/Modal'
import styled from 'styled-components'
import { fontWeights } from 'styles/typography'
import ModalContainer from 'components/Modal/ModalContainer'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import FieldMessage from 'components/FormComponents/FieldMessage'
import PasswordInput from 'components/FormComponents/Input/PasswordInput'
import ButtonCta from 'components/Button/ButtonCta'
import { validationSchema, getInitialValues } from 'components/ReturningConsumer/AgentValidationModal/formValidation'
import { isEmpty } from 'utils/validators'

const ModalInner = styled.div`
  max-width: 500px;
  text-align: center;
  margin: 24px auto;

  p {
    margin-bottom: 20px;
  }
`

const Bold = styled.span`
  font-weight: ${fontWeights.bold};
`

const AgentValidationModal = ({ updateUser, userToUpdate, resetUserForm }) => {
  const { agentInfo, isValidAgentPin, sessionError } = useContext(SessionContext)
  const [, dispatch] = useModalContext()
  const inputRef = useRef(null)

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const isValid = await isValidAgentPin({ username: agentInfo.username, pin: values.pin })

      if (isValid) {
        await updateUser({ userToUpdate, resetAgentPinForm: resetForm, resetUserForm })
        dispatch({ type: MODAL_ACTIONS.close })
      }
    } catch (error) {
      console.log(error)
      return error
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus()
      }, '500')
    }
  }, [inputRef.current])

  return (
    <ModalContainer>
      <ModalInner>
        <Section
          title={
            <span>
              Please insert your <br />
              pin to continue
            </span>
          }
          hasSubtitle
        >
          <Wrapper>
            <p>
              Logged in as <Bold>{agentInfo?.username}</Bold>
            </p>

            <p>Please verify your account by entering your pin</p>

            <Formik
              initialValues={getInitialValues()}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange
            >
              {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => {
                return (
                  <Form>
                    <Fieldset>
                      <FieldsetItem xs="1-1">
                        <PasswordInput
                          ref={inputRef}
                          value={values.pin}
                          meta={{
                            error: errors.pin,
                            touched: touched.pin
                          }}
                          name="pin"
                          placeholder="*******"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          noConditions
                        />
                      </FieldsetItem>

                      {sessionError.length > 0 && touched.pin && (
                        <FieldsetItem xs="1-1" noPaddingTop>
                          <FieldMessage>{sessionError}</FieldMessage>
                        </FieldsetItem>
                      )}
                    </Fieldset>

                    <Fieldset>
                      <FieldsetItem xs="1-1">
                        <ButtonCta type="submit" disabled={!isEmpty(errors)} isLoading={isSubmitting}>
                          Verify pin
                        </ButtonCta>
                      </FieldsetItem>
                    </Fieldset>
                  </Form>
                )
              }}
            </Formik>
          </Wrapper>
        </Section>
      </ModalInner>
    </ModalContainer>
  )
}

AgentValidationModal.propTypes = {
  updateUser: func.isRequired,
  userToUpdate: object.isRequired,
  resetUserForm: func.isRequired
}

export default AgentValidationModal
