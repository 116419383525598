import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useAccountCreation } from 'contexts/AccountCreation'
import { useLocalForage } from 'contexts/LocalForage'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import FieldMessage from 'src/components/FormComponents/FieldMessage'
import Label from 'components/FormComponents/Label'
import Input from 'components/FormComponents/Input/Input'
import Dropdown from 'components/FormComponents/Dropdown'
import NumberInput from 'components/FormComponents/Input/NumberInput'
import PostalCodeInput from 'components/FormComponents/Address/PostalCodeInput'
import ButtonCta from 'components/Button/ButtonCta'
import Checkbox from 'components/Checkbox'
import Separator from 'components/Separator'
import { GENDER_OPTIONS, FREQUENCY_OPTIONS } from 'utils/constants'
import { isEmpty, isUserObjValid } from 'utils/validators'
import { validationSchema, getInitialValues } from './formValidation'
import { emailSubscription, vusePlusOptInConsent, privacyPolicy, RegisterLabel } from './consents'

const ConsentContainer = styled.div`
  margin: 1rem 0;
`

const CreationForm = () => {
  const { user, setUser, nextStep, previousStep } = useAccountCreation()
  const { getStoredItem } = useLocalForage()
  const [agentProvince, setAgentProvince] = useState(null)

  const handleSecondStepSubmit = async values => {
    setUser({
      ...values,
      ...user,
      consent: true,
      emailSubscription: true,
      vusePlusOptIn: true,
      province: values.province || agentProvince,
      postalCode: values.postalCode
    })
    nextStep()
  }

  useEffect(() => {
    const fetchAgentProvince = async () => {
      const fetchedAgentInfo = await getStoredItem('agentInformation')
      setAgentProvince(fetchedAgentInfo.agentProvinceActivation)
    }

    fetchAgentProvince()
  }, [])

  useEffect(() => {
    // Extra check to test if the user object is well formed
    if (!isUserObjValid(user)) {
      toast.error('An error occurred please try again.')
      previousStep()
    }
  }, [user])

  return (
    <Formik
      initialValues={getInitialValues(user)}
      validationSchema={() => validationSchema(agentProvince)}
      onSubmit={handleSecondStepSubmit}
      validateOnChange
      validateOnBlur
    >
      {({ errors, touched, values, handleChange, handleBlur, setFieldValue, isSubmitting }) => {
        const isElligble = values.isSmoker || values.isVaper

        const handleIsVaperChange = async event => {
          setFieldValue('vapeFrequency', '')
          handleChange(event)
        }

        const handleIsSmokerChange = async event => {
          setFieldValue('smokeFrequency', '')
          handleChange(event)
        }

        return (
          <Form>
            <Fieldset>
              <Label subLabel="Check all that apply">Tell us which products do you currently consume</Label>

              <FieldsetItem xs="1-1" sm="1-3">
                <Checkbox
                  label={'Cigarettes'}
                  name="isSmoker"
                  onChange={handleIsSmokerChange}
                  checked={values.isSmoker}
                  error={!isElligble && (touched.isSmoker || touched.isVaper)}
                />
              </FieldsetItem>

              <FieldsetItem xs="1-1" sm="2-3" opacity={+values.isSmoker}>
                <Dropdown
                  name="smokeFrequency"
                  value={values.smokeFrequency || ''}
                  options={FREQUENCY_OPTIONS}
                  onChange={handleChange}
                  placeholder="Select frequency"
                  meta={{
                    error: errors.smokeFrequency,
                    touched: touched.smokeFrequency
                  }}
                  onBlur={handleBlur}
                  full
                />
              </FieldsetItem>

              <FieldsetItem xs="1-1" sm="1-3">
                <Checkbox
                  label={'Vaping device'}
                  name="isVaper"
                  onChange={handleIsVaperChange}
                  checked={values.isVaper}
                  error={!isElligble && (touched.isSmoker || touched.isVaper)}
                />
              </FieldsetItem>

              <FieldsetItem xs="1-1" sm="2-3" opacity={+values.isVaper}>
                <Dropdown
                  name="vapeFrequency"
                  value={values.vapeFrequency || ''}
                  options={FREQUENCY_OPTIONS}
                  onChange={handleChange}
                  placeholder="Select frequency"
                  meta={{
                    error: errors.vapeFrequency,
                    touched: touched.vapeFrequency
                  }}
                  onBlur={handleBlur}
                  full
                />
              </FieldsetItem>

              {!isElligble && (touched.isSmoker || touched.isVaper) && (
                <FieldsetItem xs="1-1">
                  <FieldMessage big>Sorry, you are ineligible to proceed further</FieldMessage>
                </FieldsetItem>
              )}
            </Fieldset>

            {isElligble && (
              <>
                <Separator />

                <Fieldset hint="Enter name and last name as displayed on your government ID.">
                  <FieldsetItem xs="1-1" sm="1-2">
                    <Input
                      label="First name"
                      name="firstName"
                      value={values.firstName}
                      autoComplete="off"
                      onChange={handleChange}
                      placeholder="John"
                      meta={{
                        error: errors.firstName,
                        touched: touched.firstName
                      }}
                      onBlur={handleBlur}
                    />
                  </FieldsetItem>
                  <FieldsetItem xs="1-1" sm="1-2">
                    <Input
                      label="Last name"
                      name="lastName"
                      value={values.lastName}
                      autoComplete="off"
                      onChange={handleChange}
                      placeholder="Doe"
                      meta={{
                        error: errors.lastName,
                        touched: touched.lastName
                      }}
                      onBlur={handleBlur}
                    />
                  </FieldsetItem>
                </Fieldset>

                <FieldsetItem xs="1-1">
                  <PostalCodeInput
                    label="Residential Postal Code"
                    name="postalCode"
                    placeholder="Type your postal code ex. H2F 5D2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    meta={{
                      error: errors.postalCode,
                      touched: touched.postalCode
                    }}
                  />
                </FieldsetItem>

                <Fieldset>
                  <FieldsetItem xs="1-1" sm="1-2">
                    <NumberInput
                      label="Date of birth"
                      name="dob"
                      value={values.dob}
                      onChange={handleChange}
                      numberFormat="####/##/##"
                      placeholder="YYYY/MM/DD"
                      autoComplete="off"
                      meta={{
                        error: errors.dob,
                        touched: touched.dob
                      }}
                      onBlur={handleBlur}
                    />
                  </FieldsetItem>

                  <FieldsetItem xs="1-1" sm="1-2">
                    <NumberInput
                      label="Phone number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      numberFormat="(###) ###-####"
                      placeholder="(555) 555-5555"
                      autoComplete="off"
                      meta={{
                        error: errors.phoneNumber,
                        touched: touched.phoneNumber
                      }}
                      onBlur={handleBlur}
                      optional
                    />
                  </FieldsetItem>
                </Fieldset>

                <Separator />

                <Fieldset>
                  <FieldsetItem xs="1-1" sm="1-2">
                    <Dropdown
                      label="Gender"
                      name="gender"
                      value={values.gender || ''}
                      options={GENDER_OPTIONS}
                      onChange={handleChange}
                      placeholder="Select Gender"
                      meta={{
                        error: errors.gender,
                        touched: touched.gender
                      }}
                      onBlur={handleBlur}
                      full
                      optional
                    />
                  </FieldsetItem>
                </Fieldset>

                <Separator />

                <ConsentContainer>
                  {RegisterLabel}
                  {emailSubscription}
                  {vusePlusOptInConsent}
                  {privacyPolicy}
                </ConsentContainer>

                <Separator />

                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <ButtonCta type="submit" disabled={!isEmpty(errors)} isLoading={isSubmitting}>
                      Register
                    </ButtonCta>
                  </FieldsetItem>
                </Fieldset>
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreationForm
