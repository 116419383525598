import React, { useState, useEffect } from 'react'
import { arrayOf, func, number, shape, string } from 'prop-types'
import { useFormikContext, FieldArray } from 'formik'
import styled from 'styled-components'
import { stripUnit } from 'polished'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontSizes } from 'styles/typography'
import { animationTime, animationCurve } from 'styles/global'
import { separatorColor } from 'styles/colors'
import { useOptionsContext } from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/OptionsContext'
import Icon from 'components/Icon'
import Separator from 'components/Separator'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import Dropdown from 'components/FormComponents/Dropdown'
import Flavour from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/Flavour'
import { bundleInitShape } from 'components/AccountCreation/CreationFormThirdStep/formValidation'

const Container = styled.div`
  ${props => (props.isFirstBundle ? `padding-top: ${spacing.large};` : ` margin-top: ${spacing.small};`)}
`

const BundleContainer = styled.div`
  padding: ${spacing.large} 30px;
  margin: 0px -30px;
  border: 1px solid white;
  transition: border ${animationTime} ${animationCurve};
  ${props => props.isBundleComplete && `border: 1px solid ${separatorColor};`}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${spacing.mini};

  ${media.medium`
    padding: 0 ${stripUnit(spacing.mini) * 1.5 + 'px'};
  `};
`

const Title = styled.p`
  font-size: ${fontSizes.tiny};
  letter-spacing: 1.2px;
  text-transform: uppercase;
`

const RemoveIcon = styled(Icon)`
  transform: rotate(45deg);
`

// Return filtered bundle with a device or flavours
export const getBundle = bundle => {
  const { deviceId, flavourIds } = bundle
  const filteredFlavours = flavourIds.filter(flavour => flavour.length)
  if (deviceId || filteredFlavours.length) return { deviceId, flavourIds: filteredFlavours }
  return null
}

const Bundle = ({ bundle, name, indexBundle, removeBundle }) => {
  const { deviceOptions } = useOptionsContext()
  const { handleChange, handleBlur, setFieldValue } = useFormikContext()
  const [isBundleComplete, setIsBundleComplete] = useState(false)

  useEffect(() => {
    const bundleSelected = getBundle(bundle)
    const isBundleComplete = bundleSelected?.deviceId && bundleSelected?.flavourIds.length > 0
    setIsBundleComplete(isBundleComplete)
  }, [bundle])

  useEffect(() => {
    // If no device selected, reset bundle
    if (!bundle.deviceId) setFieldValue(name, bundleInitShape)
    else if (bundle.deviceId) {
      // On device change, reset bundle flavour values
      setFieldValue(name, { deviceId: bundle.deviceId, flavourIds: [''] })
    }
  }, [bundle.deviceId])

  return (
    <Container isFirstBundle={indexBundle === 0}>
      {indexBundle === 0 && !isBundleComplete && <Separator />}

      <BundleContainer isBundleComplete={isBundleComplete}>
        {isBundleComplete && (
          <Header>
            <Title>Selected device #{indexBundle + 1}</Title>
            {indexBundle > 0 && (
              <button type="button" onClick={() => removeBundle(indexBundle)}>
                <RemoveIcon icon="plus" customSize="22px" />
              </button>
            )}
          </Header>
        )}

        <Fieldset>
          <FieldsetItem xs="1-1" noPaddingBottom>
            <Dropdown
              label="What device did we offer the consumer?"
              name={`${name}.deviceId`}
              value={bundle.deviceId}
              options={deviceOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Select Device"
              full
              optional={2}
            />
          </FieldsetItem>
        </Fieldset>

        <Fieldset>
          <FieldArray name={`${name}.flavourIds`}>
            {({ push, remove }) => (
              <>
                {bundle.flavourIds.length > 0 &&
                  bundle.flavourIds.map((flavour, indexFlavour) => (
                    <Flavour
                      key={indexFlavour}
                      bundle={bundle}
                      flavour={flavour}
                      name={`${name}.flavourIds.${indexFlavour}`}
                      indexFlavour={indexFlavour}
                      pushFlavour={push}
                      removeFlavour={remove}
                    />
                  ))}
              </>
            )}
          </FieldArray>
        </Fieldset>
      </BundleContainer>
    </Container>
  )
}

Bundle.propTypes = {
  bundle: shape({ deviceId: string, flavourIds: arrayOf(string) }).isRequired,
  name: string.isRequired,
  indexBundle: number.isRequired,
  removeBundle: func.isRequired
}

export default Bundle
