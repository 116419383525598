import {
  getReferrersApi,
  getAgentAppDataApi,
  agentVerifyEmailApi,
  agentUpdateUserApi,
  agentSourcesByChannelApi
} from 'api/agents/agentsApi.js'

export const getReferrers = ({ term }) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await getReferrersApi({ term })
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })

export const getAgentAppData = () =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await getAgentAppDataApi()
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })

export const agentVerifyEmail = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await agentVerifyEmailApi(payload)
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })

export const agentUpdateUser = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await agentUpdateUserApi(payload)
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })

export const agentSourcesByChannel = payload =>
  new Promise(async (resolve, reject) => {
    try {
      const data = await agentSourcesByChannelApi(payload)
      resolve(data)
    } catch (error) {
      reject(error.response)
    }
  })
