import React from 'react'
import { number, string } from 'prop-types'
import styled from 'styled-components'
import * as spacing from 'styles/spacing'
import { fontWeights } from 'styles/typography'
import Icon from 'components/Icon'
import { numberWithComma } from 'utils/formatters'

const Container = styled.div`
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${spacing.large};
  padding: 40px 60px;
  margin: ${spacing.large} 0;

  p {
    margin-bottom: 0;
  }
`

const Balance = styled.p`
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};
`

const Perk = styled.p`
  font-weight: ${fontWeights.light};
`

const Tier = styled.span`
  text-transform: capitalize;
`

const VRIcon = styled(Icon)`
  width: 157px;
  height: 42px;
`

const VuseRewardsTier = ({ tier, points }) => {
  return (
    <Container>
      <VRIcon icon="vuse-rewards/logoVR" />
      <Balance>You have a balance of {numberWithComma(points)} points that you can use on your next purchase</Balance>
      <Perk>
        As a{' '}
        <strong>
          <Tier>{tier}</Tier> member
        </strong>
        , you have access to free shipping on vuse.com.
      </Perk>
    </Container>
  )
}

VuseRewardsTier.propTypes = {
  tier: string.isRequired,
  points: number.isRequired
}

export default VuseRewardsTier
