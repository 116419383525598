import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { string, object, bool } from 'prop-types'
import { stripUnit } from 'polished'

import Label from 'components/FormComponents/Label'
import FieldMessage from 'src/components/FormComponents/FieldMessage'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { field } from 'styles/form'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Wrap = styled.div`
  position: relative;

  ${props =>
    props.narrow &&
    `
    max-width: 80px;
  `};
`

const Field = styled.input`
  ${field}

  &::placeholder {
    text-transform: none;
  }
`

const Textarea = styled(Field)`
  padding: ${stripUnit(spacing.medium) - 2 + 'px'} ${spacing.medium};
  min-height: 160px;
  resize: vertical;

  ${media.small`
    min-height: 200px;
  `};

  ${media.medium`
    min-height: 240px;
  `};

  ${media.large`
    min-height: 260px;
  `};
`

const Input = forwardRef(({ label, disabled, narrow, input, textarea, meta = {}, optional, ...rest }, ref) => (
  <Container>
    {label && (
      <Label optional={optional}>
        {label}
      </Label>
    )}

    <Wrap narrow={narrow}>
      {!textarea && (
        <Field
          ref={ref}
          error={(meta.error && meta.touched) || meta.formError}
          disabled={disabled}
          {...input}
          {...rest}
        />
      )}
      {textarea && (
        <Textarea ref={ref} as="textarea" error={(meta.error && meta.touched) || meta.formError} {...input} {...rest} />
      )}
    </Wrap>
    {meta && meta.touched && meta.error && <FieldMessage>{meta.error}</FieldMessage>}
  </Container>
))

Input.propTypes = {
  label: string,
  disabled: bool,
  narrow: bool,
  textarea: bool,
  input: object,
  meta: object,
  optional: bool
}

export default Input
