import React from 'react'
import { string, object, bool } from 'prop-types'
import NumberFormat from 'react-number-format'

import Input from 'components/FormComponents/Input/Input'

const NumberInput = ({ numberFormat, input, optional, ...props }) => (
  <NumberFormat customInput={Input} optional={optional} format={numberFormat} {...input} {...props} />
)

NumberInput.propTypes = {
  numberFormat: string,
  input: object,
  optional: bool
}

export default NumberInput
