import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { object, node, func, bool, string, number, oneOfType, array } from 'prop-types'
import * as spacing from 'styles/spacing'
import { field } from 'styles/form'
import Select from 'react-select'
import { inputBackColor, disabledInputBackColor } from 'styles/colors'

import Icon from 'components/Icon'
import Tip from 'components/Tip'
import Label from 'components/FormComponents/Label'
import FieldMessage from 'src/components/FormComponents/FieldMessage'

const Container = styled.div`
  display: inline-block;

  ${props =>
    props.full &&
    css`
      display: block;
      width: 100%;
    `};
`

const Wrap = styled.div`
  position: relative;
`

const Content = styled.div`
  position: relative;
`

const SelectDropDown = styled(Select)`
  ${field}
  cursor: pointer;
  padding: 0;
  .react-select__indicators {
    display: none;
  }
`

export const DropdownIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${spacing.medium};
  transform: translateY(-50%);
  pointer-events: none;
`
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '47px',
    border: 0,
    padding: '0 4px',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: state?.isDisabled ? disabledInputBackColor : inputBackColor,
    borderColor: inputBackColor
  }),
  option: (provided, state) => ({
    ...provided,
    maxHeight: '30px',
    backgroundColor: state.isFocused ? '#3961CA' : 'inherit',
    color: state.isFocused ? 'white' : 'inherit'
  }),
  menu: provided => ({
    ...provided,
    zIndex: 4
  }),
  placeholder: provided => ({
    ...provided,
    color: '#2a2a2a'
  })
}

const DropdownWithSearch = ({
  placeholder,
  options,
  onChange,
  onBlur,
  value,
  label,
  full,
  name,
  meta,
  tip,
  optional,
  disabled,
  sortOptions,
  setFieldValue,
  setTouched,
  ...rest
}) => {
  const [displayTip, setDisplayTip] = useState(false)
  const [optionToDisplay, setOptionToDisplay] = useState([])
  const handleDropDownChange = selectedOption => {
    setFieldValue(name, selectedOption.value)
  }

  useEffect(() => {
    const tempOptionsToDisplay = options
    const firstOption = { value: 'disabled', label: placeholder, disabled: true }
    tempOptionsToDisplay.unshift(firstOption)
    setOptionToDisplay(tempOptionsToDisplay)
  }, [options])

  return (
    <Container full={full} {...rest}>
      {label && (
        <Label optional={optional} disabled={disabled}>
          {label}
        </Label>
      )}

      <Wrap>
        <Content>
          <SelectDropDown
            onChange={selectedOption => {
              handleDropDownChange(selectedOption)
            }}
            options={optionToDisplay}
            name={name}
            placeholder={placeholder}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null
            }}
            onBlur={() => {
              setTouched({ [name]: true })
            }}
            styles={customStyles}
            isDisabled={disabled}
            isOptionDisabled={option => option.disabled}
          ></SelectDropDown>
          <DropdownIcon icon="dropdown" />
        </Content>

        {meta && meta.error && meta.touched && <FieldMessage>{meta.error}</FieldMessage>}

        {tip && <Tip tip={tip} displayTip={displayTip} setDisplayTip={setDisplayTip} />}
      </Wrap>
    </Container>
  )
}

DropdownWithSearch.propTypes = {
  label: oneOfType([bool, string]),
  placeholder: string,
  name: string,
  options: array.isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  setTouched: func.isRequired,
  setFieldValue: func.isRequired,
  full: bool,
  value: oneOfType([string, number]),
  meta: object,
  tip: node,
  optional: oneOfType([bool, number]),
  disabled: bool,
  sortOptions: func
}

export default DropdownWithSearch
