import * as yup from 'yup'

export const getInitialValues = () => {
  return {
    pin: ''
  }
}

export const validationSchema = props => {
  return yup.lazy(values => {
    return yup.object().shape({
      pin: yup.string().required('Pin is required')
    })
  })
}
