import React, { useContext } from 'react'
import styled from 'styled-components'
import InlineSVG from 'svg-inline-react'
import { Link } from 'react-router-dom'

import * as spacing from 'styles/spacing'
import { backgroundColor, white, greyLight } from 'styles/colors'
import { animationTime, animationCurve } from 'styles/global'
import { SessionContext } from 'contexts/Session'
import { media } from 'styles/media'

const Header = styled.header`
  position: relative;
  background-color: ${backgroundColor};
  height: 160px;
  padding: 30px ${spacing.xxxLarge};
  color: ${greyLight};
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const Inner = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoWrap = styled(Link)`
  display: flex;
  ${media.medium`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `};
`

const Logo = styled(InlineSVG)`
  fill: ${white};
  width: 104px;
  height: 31px;
  margin: 0 auto;

  ${media.medium`
    width: 114px;
    height: 34px;
  `};

  ${media.large`
    width: 136px;
    height: 32px;
  `};
`

const Details = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`

const Action = styled.div`
  display: flex;
  align-items: center;
  color: ${white};
`

const Logout = styled(InlineSVG)`
  display: flex;
  width: 40px;
  transition: color ${animationTime} ${animationCurve};
  padding-left: ${spacing.medium};

  &:hover {
    color: ${white};
    cursor: pointer;
  }
`

export default () => {
  const { loggedIn, agentInfo, logout } = useContext(SessionContext)

  return (
    <Header>
      <Content>
        <Inner>
          <LogoWrap to="/">
            <Logo src={require(`static/images/logo.svg`)} raw />
          </LogoWrap>
          <Details>
            {loggedIn && agentInfo && (
              <>
                <Action>
                  {agentInfo.name}
                  <Logout src={require(`static/images/logout.svg`)} raw onClick={logout} />
                </Action>
              </>
            )}
          </Details>
        </Inner>
      </Content>
    </Header>
  )
}
