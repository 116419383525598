import React from 'react'
import styled from 'styled-components'
import { useAccountCreation } from 'contexts/AccountCreation'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import UpdateForm from './UpdateForm'
import VuseRewardsTier from './VuseRewardsTier'

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`

const FormContainer = styled.div`
  width: 100%;
`

const ReturningConsumerFirstStep = () => {
  const { user } = useAccountCreation()

  return (
    <Section title={'Confirm your details'} hasSubtitle>
      <Wrapper>
        <Content>
          <p>Make sure your information is up to date to get the best Vuse experience.</p>

          <FormContainer>
            <UpdateForm />
          </FormContainer>

          {user.rewards && user.rewards.isRewardsMember && (
            <VuseRewardsTier tier={user.rewards.tier} points={user.rewards.availablePoints} />
          )}
        </Content>
      </Wrapper>
    </Section>
  )
}

export default ReturningConsumerFirstStep
