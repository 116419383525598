import React from 'react'
import { createRoot } from 'react-dom/client'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import { BrowserRouter } from 'react-router-dom'
import { SessionProvider } from 'contexts/Session'
import { LocalForageProvider } from 'contexts/LocalForage'
import { ModalContextProvider } from 'contexts/Modal'

import { waitOnCache } from 'lib/graphql/client'

import App from './App.js'

OfflinePluginRuntime.install()

const Skeleton = () => (
  <BrowserRouter>
    <LocalForageProvider>
      <ModalContextProvider>
        <SessionProvider>
          <App />
        </SessionProvider>
      </ModalContextProvider>
    </LocalForageProvider>
  </BrowserRouter>
)

waitOnCache.then(() => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(<Skeleton />)
})
