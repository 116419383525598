import React from 'react'
import { bool, func, node } from 'prop-types'
import styled from 'styled-components'
import * as spacing from 'styles/spacing'
import Icon from 'components/Icon'

const INFO_ICON_SIZE = '43px'

const InfoIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: calc(-${INFO_ICON_SIZE} - ${spacing.small});
  cursor: pointer;
`

const TipContent = styled.div`
  margin-top: ${spacing.medium};
  display: ${props => props.display};
`

const Tip = ({ tip, displayTip, setDisplayTip }) => (
  <>
    <InfoIcon icon="info" customSize={INFO_ICON_SIZE} onClick={() => setDisplayTip(!displayTip)} />
    <TipContent display={displayTip ? 'block' : 'none'}>{tip}</TipContent>
  </>
)

Tip.propTypes = {
  tip: node.isRequired,
  displayTip: bool.isRequired,
  setDisplayTip: func.isRequired
}

export default Tip
