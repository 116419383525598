export const MODAL_ACTIONS = { open: 'open', close: 'close' }

export const initialState = {
  isModalOpen: false,
  content: null
}

export const modalReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case MODAL_ACTIONS.open:
      return {
        isModalOpen: true,
        content: payload.content
      }

    case MODAL_ACTIONS.close: {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
