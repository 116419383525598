import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { getProvinceFromPostalCode, isPostalCodeAllowed } from 'utils/validators'
import Input from 'components/FormComponents/Input/Input'

const Container = styled.div`
  input {
    text-transform: uppercase;
  }
`

const PostalCodeInput = props => {
  const { setFieldValue } = useFormikContext()

  const onPostalCodeChange = event => {
    const input = event.target.value

    // Remove all non-alphanumeric characters
    const cleanInput = input.replace(/[^a-z0-9]/gi, '')

    // Add a space after 3 characters to format postalCode
    let formattedInput
    if (cleanInput.length <= 3) {
      formattedInput = cleanInput
    } else if (cleanInput.length <= 6) {
      formattedInput = cleanInput.slice(0, 3) + ' ' + cleanInput.slice(3)
    } else {
      formattedInput = cleanInput.slice(0, 3) + ' ' + cleanInput.slice(3, 6)
    }
    const newValue = formattedInput.toUpperCase()

    const correspondingProvince = getProvinceFromPostalCode(newValue)

    if (isPostalCodeAllowed(newValue, correspondingProvince)) {
      setFieldValue('province', correspondingProvince)
    }

    event.target.value = newValue
    props.onChange(event)
  }

  return (
    <Container>
      <Input {...props} onChange={onPostalCodeChange} />
    </Container>
  )
}

PostalCodeInput.propTypes = {
  onChange: func.isRequired
}

export default PostalCodeInput
