import React from 'react'
import styled, { css } from 'styled-components'
import { bool, node, number, oneOfType, string } from 'prop-types'
import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { disabledTextColor, placeholderColor } from 'styles/colors'
import { media } from 'styles/media'

const Optional = styled.span`
  color: ${placeholderColor};
  ${props => props.disabled && `color: ${disabledTextColor}`}
`

const handleOptionalStyle = theme => {
  switch (theme) {
    case 1:
      return css`
        justify-content: space-between;
      `
    case 2:
      return css`
        justify-content: flex-start;
        gap: 5px;

        ${Optional} {
          text-transform: uppercase;
          font-weight: ${fontWeights.heavy};

          &:before {
            content: '(';
          }
          &:after {
            content: ')';
          }
        }
      `
    default:
      return ''
  }
}

const LabelContainer = styled.div`
  display: flex;
  ${props => handleOptionalStyle(props.theme)};
`

const Container = styled.div`
  width: 100%;
  padding: ${spacing.mini} 0;

  ${media.medium`
  padding: ${spacing.small} 0;
`};
`

const CustomLabel = styled.label`
  display: block;
  margin-bottom: ${spacing.mini};
  font-weight: ${fontWeights.heavy};
  text-transform: uppercase;
  text-align: left;

  ${props => props.disabled && `color: ${disabledTextColor}`}
`

const SubLabel = styled.p`
  color: ${placeholderColor};
`

const Label = ({ children, subLabel, optional, disabled }) => (
  <>
    {subLabel ? (
      <Container>
        <LabelContainer theme={+optional}>
          <CustomLabel disabled={disabled}>{children}</CustomLabel>
          {optional && (
            <Optional theme={+optional} disabled={disabled}>
              Optional
            </Optional>
          )}
        </LabelContainer>

        {subLabel && <SubLabel>{subLabel}</SubLabel>}
      </Container>
    ) : (
      <LabelContainer theme={+optional}>
        <CustomLabel disabled={disabled}>{children}</CustomLabel>
        {optional && (
          <Optional theme={+optional} disabled={disabled}>
            Optional
          </Optional>
        )}
      </LabelContainer>
    )}
  </>
)

Label.propTypes = {
  children: node.isRequired,
  subLabel: string,
  optional: oneOfType([bool, number]), // optional can be a bool (0 || 1) or a number (1 || 2) to specify the theme
  disabled: bool
}

export default Label
