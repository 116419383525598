import React, { useContext } from 'react'
import { object, func, bool } from 'prop-types'
import { withFormik } from 'formik'
import * as yup from 'yup'
import { isEmpty } from 'utils/validators.js'
import { SessionContext } from 'contexts/Session'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import Input from 'components/FormComponents/Input/Input'
import FieldMessage from 'src/components/FormComponents/FieldMessage'
import ButtonCta from 'components/Button/ButtonCta'

const AgentLoginForm = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
  const { loggedIn, sessionError, setSessionError } = useContext(SessionContext)

  const modifiedHandleChange = event => {
    if (sessionError.length > 0 && isEmpty(errors)) setSessionError('')
    handleChange(event)
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Fieldset>
        <FieldsetItem xs="1-1" sm="3-4">
          <Input
            type="text"
            label="Username"
            name="username"
            placeholder="johndoe"
            value={values.username}
            onChange={modifiedHandleChange}
            onBlur={handleBlur}
            disabled={loggedIn}
            meta={{
              error: errors.username,
              formError: sessionError,
              touched: touched.username
            }}
          />
        </FieldsetItem>

        <FieldsetItem xs="1-1" sm="1-4">
          <Input
            type="password"
            label="Pin"
            name="pin"
            placeholder="****"
            value={values.pin}
            onChange={modifiedHandleChange}
            onBlur={handleBlur}
            disabled={loggedIn}
            meta={{
              error: errors.pin,
              formError: sessionError,
              touched: touched.pin
            }}
          />
        </FieldsetItem>

        {sessionError.length > 0 && touched.username && touched.pin && (
          <FieldsetItem xs="1-1" noPaddingTop>
            <FieldMessage>{sessionError}</FieldMessage>
          </FieldsetItem>
        )}

        {!loggedIn && (
          <FieldsetItem xs="1-1">
            <ButtonCta
              type="submit"
              disabled={loggedIn || ((!isEmpty(errors) || sessionError.length > 0) && touched.username && touched.pin)}
              isLoading={isSubmitting}
            >
              Log in
            </ButtonCta>
          </FieldsetItem>
        )}
      </Fieldset>
    </form>
  )
}

AgentLoginForm.propTypes = {
  values: object.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired
}

const mapPropsToValues = () => ({
  pin: '',
  username: ''
})

const validationSchema = yup.object().shape({
  pin: yup.string().required('Pin is required'),
  username: yup.string().required('Username is required')
})

const handleSubmit = async (values, { props }) => {
  await props.loginAgent(values)
}

export default withFormik({ mapPropsToValues, validationSchema, handleSubmit })(AgentLoginForm)
