import React from 'react'
import { Form, Formik } from 'formik'
import omit from 'lodash/omit'
import { useAccountCreation } from 'contexts/AccountCreation'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import Input from 'components/FormComponents/Input/Input'
import Dropdown from 'components/FormComponents/Dropdown'
import NumberInput from 'components/FormComponents/Input/NumberInput'
import PostalCodeInput from 'components/FormComponents/Address/PostalCodeInput'
import FullAddress from 'components/AccountCreation/CreationFormSecondStep/FullAddress'
import ButtonCta from 'components/Button/ButtonCta'
import { GENDER_OPTIONS } from 'utils/constants'
import { isEmpty } from 'utils/validators'
import { validationSchema, getInitialValues } from './formValidation'

const UpdateForm = () => {
  const { user, setUser, nextStep } = useAccountCreation()

  const handleSubmit = async values => {
    setUser({
      ...omit(values, ['street', 'manualEntry', 'startToEnterAddress']),
      ...user,
      ...values
    })
    nextStep()
  }

  return (
    <Formik
      initialValues={getInitialValues(user)}
      validationSchema={validationSchema(user)}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
    >
      {({ errors, touched, values, handleChange, handleBlur, isSubmitting }) => {
        return (
          <Form>
            <Fieldset hint="Enter name and last name as displayed on your government ID.">
              <FieldsetItem xs="1-1" sm="1-2">
                <Input
                  label="First name"
                  name="firstName"
                  value={values.firstName}
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder="John"
                  meta={{
                    error: errors.firstName,
                    touched: touched.firstName
                  }}
                  onBlur={handleBlur}
                />
              </FieldsetItem>
              <FieldsetItem xs="1-1" sm="1-2">
                <Input
                  label="Last name"
                  name="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder="Doe"
                  meta={{
                    error: errors.lastName,
                    touched: touched.lastName
                  }}
                  onBlur={handleBlur}
                />
              </FieldsetItem>
            </Fieldset>

            {user.address ? (
              <FullAddress />
            ) : (
              <FieldsetItem xs="1-1">
                <PostalCodeInput
                  label="Residential Postal Code"
                  name="postalCode"
                  value={values.postalCode}
                  placeholder="Type your postal code ex. H2F 5D2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  meta={{
                    error: errors.postalCode,
                    touched: touched.postalCode
                  }}
                />
              </FieldsetItem>
            )}

            <Fieldset>
              <FieldsetItem xs="1-1" sm="1-2">
                <Input
                  type="email"
                  label="Email address"
                  name="email"
                  placeholder="johndoe@domain.com"
                  value={values.email}
                  disabled
                  autoComplete="off"
                  meta={{
                    error: errors.email,
                    touched: touched.email
                  }}
                />
              </FieldsetItem>

              <FieldsetItem xs="1-1" sm="1-2">
                <NumberInput
                  label="Phone number"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  numberFormat="(###) ###-####"
                  placeholder="(555) 555-5555"
                  autoComplete="off"
                  meta={{
                    error: errors.phoneNumber,
                    touched: touched.phoneNumber
                  }}
                  optional
                />
              </FieldsetItem>
            </Fieldset>

            <Fieldset>
              <FieldsetItem xs="1-1" sm="1-2">
                <Dropdown
                  label="Gender"
                  name="gender"
                  value={values.gender}
                  options={GENDER_OPTIONS}
                  onChange={handleChange}
                  placeholder="Select Gender"
                  meta={{
                    error: errors.gender,
                    touched: touched.gender
                  }}
                  onBlur={handleBlur}
                  full
                  optional
                />
              </FieldsetItem>
            </Fieldset>

            <Fieldset>
              <FieldsetItem xs="1-1">
                <ButtonCta type="submit" disabled={!isEmpty(errors)} isLoading={isSubmitting}>
                  Next
                </ButtonCta>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UpdateForm
