import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useLocalForage } from 'contexts/LocalForage'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import VuseRewardsPerks from 'components/AccountCreation/CreationFormSecondStep/VuseRewardsPerks'
import CreationForm from './CreationForm'

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`

const FormContainer = styled.div`
  width: 100%;
`

const CreationFormSecondStep = () => {
  const { getStoredItem } = useLocalForage()
  const [isDarkMarket, setIsDarkMarket] = useState(true)

  useEffect(() => {
    const setDarkMarket = async () => {
      const darkMarket = await getStoredItem('darkMarket')
      setIsDarkMarket(darkMarket)
    }

    setDarkMarket()
  }, [])

  return (
    <Section title={'Not a member? Join us now'} hasSubtitle={!isDarkMarket}>
      <Wrapper>
        <Content>
          {!isDarkMarket && <VuseRewardsPerks />}

          <FormContainer>
            <CreationForm />
          </FormContainer>
        </Content>
      </Wrapper>
    </Section>
  )
}

export default CreationFormSecondStep
