import React from 'react'
import styled from 'styled-components'
import { bool, node, string, oneOfType } from 'prop-types'

import { successColor, errorColor } from 'styles/colors'
import { fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'
import Icon from 'components/Icon'

const Container = styled.div`
  display: flex;
  margin-top: ${spacing.mini};
  justify-content: ${props => props.align};
`

const Cross = styled.div`
  margin: 3.5px;
`

const Message = styled.small`
  color: ${props => (props.type === 'success' ? successColor : errorColor)};
  display: block;
  font-size: ${props => (props.big ? `${fontSizes.regular}` : `${fontSizes.small}`)};
`

const FieldMessage = ({ children, big, icon = 'cross-red', type = 'error', align = 'flex-start' }) => (
  <Container align={align}>
    {icon !== false && (
      <Cross>
        <Icon icon={icon} small />
      </Cross>
    )}
    <Message type={type} big={big}>
      {children}
    </Message>
  </Container>
)

FieldMessage.propTypes = {
  children: node.isRequired,
  big: bool,
  icon: oneOfType([string, bool]),
  type: string,
  align: string
}

export default FieldMessage
