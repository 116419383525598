export const CANADIAN_PROVINCES = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  Saskatchewan: 'SK',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  'Newfoundland and Labrador': 'NL'
}

export const GENDER_OPTIONS = {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
  'Prefer not to answer': 'Prefer not to answer'
}

export const CANADIAN_LEGAL_AGES = {
  AB: 18,
  BC: 19,
  MB: 18,
  NB: 19,
  SK: 19,
  ON: 19,
  PE: 21,
  NL: 19
}

export const ALLOWED_PROVINCES = Object.values(CANADIAN_PROVINCES)

export const POSTAL_CODE_START_BY_PROVINCE = {
  AB: ['T'],
  BC: ['V'],
  MB: ['R'],
  NB: ['E'],
  NL: ['A'],
  NT: ['X'],
  NS: ['B'],
  NU: ['X'],
  ON: ['K', 'L', 'M', 'N', 'P'],
  PE: ['C'],
  QC: ['G', 'H', 'J'],
  SK: ['S'],
  YT: ['Y']
}

export const AGENT_CONSENT_TEXT = `I, the data collector, have confirmed by viewing the Participant's ID that he/she has reached the age of majority or the legal age to purchase Vuse products in their province of residence, whichever is higher.`

export const NO_INTERNET_TEXT = 'Internet connection problem, try again in a moment'

export const ADRESS_ERROR_MESSAGE =
  'Sorry! Vuse is not available in Quebec, Nova Scotia, Yukon, Northwest Territories, and Nunavut. Please enter an address from a different province to access the site.'

export const FREQUENCY_OPTIONS = {
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  'Used it in the past, but not in the last 30 days': 'Not in the last 30 days'
}

export const LAST_USAGE_OPTIONS = {
  Today: 'Today',
  'Within this week': 'Within this week',
  'Within this month': 'Within this month',
  'Over a month ago': 'Over a month ago',
  'Never used': 'Never used'
}

export const USER_TYPE = {
  new: 'new',
  returning: 'returning'
}

export const CONSUMER_MAX_AGE = process.env.CONSUMER_MAX_AGE || 100
export const AGENT_CHANNEL = {
  ownedRetail: 'Owned Retail'
}
