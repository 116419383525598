import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { placeholderColor } from 'styles/colors'
import FieldsetItem from './FieldsetItem'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${spacing.mini} 0;

  ${media.medium`
    padding: ${spacing.small} 0;
  `};
`

const Hint = styled.p`
  color: ${placeholderColor};
`

const Fieldset = ({ children, hint }) => (
  <Container>
    {children}

    {hint && (
      <FieldsetItem noPaddingTop noPaddingBottom>
        <Hint>{hint}</Hint>
      </FieldsetItem>
    )}
  </Container>
)

Fieldset.propTypes = {
  children: node.isRequired,
  hint: string
}

export default Fieldset
