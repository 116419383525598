import * as yup from 'yup'

export const bundleInitShape = {
  deviceId: '',
  flavourIds: ['']
}

export const getInitialValues = user => {
  return {
    lastVuseProductUsed: user.lastVuseProductUsed || '',
    userRefererId: user.userRefererId || '',
    bundlesGiven: user.bundlesGiven || [bundleInitShape],
    preferredBrandVapeValue: '',
    preferredBrandCigaretteValue: ''
  }
}

export const validationSchema = props => {
  return yup.lazy(values => {
    return yup.object().shape({
      lastVuseProductUsed: yup.string().required('Last usage is required')
    })
  })
}
