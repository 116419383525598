import React, { useState, useEffect, forwardRef } from 'react'
import styled from 'styled-components'
import InlineSVG from 'svg-inline-react'
import { string, object, bool } from 'prop-types'
import { getPasswordValidationCondition } from 'utils/validators'
import PasswordValidationUI from 'components/PasswordValidationUI'
import { errorColor } from 'styles/colors'

import Input from 'components/FormComponents/Input/Input'

const IconWrapper = styled.div`
  position: relative;
`

const EyeButton = styled(InlineSVG)`
  position: absolute;
  top: ${props => (props.haslabel ? '42px' : '10px')};
  right: 20px;
  width: 28px;
  height: 28px;
  cursor: pointer;
`
const PasswordInput = forwardRef(({ value, noConditions, meta, label, ...props }, ref) => {
  const [showPassword, setShowPassword] = useState(false)
  const [passwordConditions, setPasswordConditions] = useState(null)
  const [hasUserInteracted, setUserInteracted] = useState(false)

  useEffect(() => {
    if (!noConditions) {
      const conditions = getPasswordValidationCondition({
        passwordValue: value,
        passwordError: meta.error
      })
      setPasswordConditions(conditions)
    }

    setUserInteracted(meta.touched || value.length > 0)
  }, [value])

  return (
    <>
      <IconWrapper>
        <Input
          ref={ref}
          type={showPassword ? 'text' : 'password'}
          value={value}
          label={label}
          {...props}
          style={{
            borderBottom: hasUserInteracted && meta.error ? `1px solid ${errorColor}` : '1px solid black'
          }}
        />
        {!showPassword ? (
          <EyeButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            src={require(`static/icons/eye-open.svg`)}
            haslabel={label}
          />
        ) : (
          <EyeButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            src={require(`static/icons/eye-close.svg`)}
            haslabel={label}
          />
        )}
      </IconWrapper>
      {passwordConditions && (
        <PasswordValidationUI conditions={passwordConditions} hasUserInteracted={hasUserInteracted} />
      )}
    </>
  )
})

PasswordInput.propTypes = {
  value: string.isRequired,
  label: string,
  noConditions: bool,
  meta: object
}

export default PasswordInput
