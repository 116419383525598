import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { fontWeights } from 'styles/typography'

const Button = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 10%;
`

const Text = styled.span`
  text-transform: uppercase;
  font-weight: ${fontWeights.heavy};
`

const Back = ({ onClick }) => (
  <Button onClick={onClick}>
    <Icon icon={'arrow-right'} small />
    <Text>Back</Text>
  </Button>
)

Back.propTypes = {
  onClick: func.isRequired
}

export default Back
