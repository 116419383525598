import React from 'react'
import styled from 'styled-components'
import { placeholderColor } from 'styles/colors'

const Container = styled.div`
  color: ${placeholderColor};

  p {
    margin-bottom: 0;
  }
`

const AgentSourcesTip = () => (
  <Container>
    <p>
      <strong>Select the option that best describes your situation.</strong> Don't forget to update this information if
      you change location throughout the day.
    </p>
    <br />
    <p>Vuse Vape Store - Calgary, Ottawa, Queen Street, Vaughan Mills, West Edmonton Mall</p>
    <p>Entertainment - Festivals, HORECA</p>
    <p>Vape Shop - Vape Shop, Vape Shop Survey</p>
  </Container>
)

export default AgentSourcesTip
