import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { object, node, func, bool, string, number, oneOfType } from 'prop-types'
import * as spacing from 'styles/spacing'
import { field } from 'styles/form'

import Icon from 'components/Icon'
import Tip from 'components/Tip'
import Label from 'components/FormComponents/Label'
import FieldMessage from 'src/components/FormComponents/FieldMessage'

const Container = styled.div`
  display: inline-block;

  ${props =>
    props.full &&
    css`
      display: block;
      width: 100%;
    `};
`

const Wrap = styled.div`
  position: relative;
`

const Content = styled.div`
  position: relative;
`

const Select = styled.select`
  ${field}
  cursor: pointer;
`

export const DropdownIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: ${spacing.medium};
  transform: translateY(-50%);
  pointer-events: none;
`

const Dropdown = ({
  placeholder,
  options,
  onChange,
  value,
  label,
  full,
  name,
  meta,
  tip,
  optional,
  disabled,
  sortOptions,
  ...rest
}) => {
  const [optionsArray, setOptionsArray] = useState([])
  const [displayTip, setDisplayTip] = useState(false)

  useEffect(() => {
    let array = Object.entries(options)
    if (sortOptions) array = sortOptions()
    setOptionsArray(array)
  }, [options])

  return (
    <Container full={full} {...rest}>
      {label && (
        <Label optional={optional} disabled={disabled}>
          {label}
        </Label>
      )}

      <Wrap>
        <Content>
          <Select
            onChange={onChange}
            value={value}
            name={name}
            error={meta && meta.error && meta.touched}
            disabled={disabled}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {optionsArray.map(([name, val], i) => (
              <option key={i} value={val}>
                {name}
              </option>
            ))}
          </Select>
          <DropdownIcon icon="dropdown" />
        </Content>

        {meta && meta.error && meta.touched && <FieldMessage>{meta.error}</FieldMessage>}

        {tip && <Tip tip={tip} displayTip={displayTip} setDisplayTip={setDisplayTip} />}
      </Wrap>
    </Container>
  )
}

Dropdown.propTypes = {
  label: oneOfType([bool, string]),
  placeholder: string,
  name: string,
  options: object.isRequired,
  onChange: func.isRequired,
  full: bool,
  value: oneOfType([string, number]),
  meta: object,
  tip: node,
  optional: oneOfType([bool, number]),
  disabled: bool,
  sortOptions: func
}

export default Dropdown
