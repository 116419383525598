import React from 'react'
import { node } from 'prop-types'
import { MODAL_ACTIONS, useModalContext } from 'contexts/Modal'

import styled from 'styled-components'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { white } from 'styles/colors'
import Icon from 'components/Icon'

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100%;
  align-items: center;
  padding: ${spacing.medium};

  ${media.small`
    padding: ${spacing.large};
  `};

  ${media.medium`
    padding: ${spacing.xLarge};
  `};

  ${media.large`
    padding: ${spacing.xxxLarge};
  `};
`

const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  max-width: 800px;
  background: ${white};
  border-radius: 8px;
  overflow: hidden;
  z-index: 450;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 450;
  padding: ${spacing.medium};
  display: flex;
`

const CloseIcon = styled(Icon)`
  width: 32px;
`

const ModalContainer = ({ children }) => {
  const [, dispatch] = useModalContext()

  const handleClose = () => {
    dispatch({ type: MODAL_ACTIONS.close })
  }

  return (
    <Container>
      <Inner>
        <CloseButton onClick={handleClose} type="button">
          <CloseIcon icon="close" />
        </CloseButton>

        {children}
      </Inner>
    </Container>
  )
}

ModalContainer.propTypes = {
  children: node.isRequired
}

export default ModalContainer
