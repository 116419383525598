import React, { useEffect, useRef } from 'react'
import { bool } from 'prop-types'
import { useFormikContext } from 'formik'
import { ALLOWED_PROVINCES, ADRESS_ERROR_MESSAGE } from 'utils/constants'
import useScript from 'hooks/useScript'
import Input from 'components/FormComponents/Input/Input'

const AddressComplete = props => {
  const el = useRef(null)
  const {
    values,
    setFieldValue,
    setFieldTouched,
    setErrors,
    handleChange,
    handleBlur,
    validateField
  } = useFormikContext()
  const { loaded } = useScript(
    `https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=${process.env.CANADAPOST_API_KEY}&manualEntry=true`
  )

  useEffect(() => {
    let mounted = true
    const initAddressComplete = () => {
      if (window !== 'undefined') {
        window.addressComplete.listen('load', control => {
          if (!mounted) return

          control.listen('populate', address => {
            if (!mounted) return
            const { FormattedLine1, City, PostalCode, Province, CountryName, SubBuilding } = address

            setFieldValue('address', FormattedLine1)
            setFieldValue('city', City)
            setFieldValue('postalCode', PostalCode)
            setFieldValue('province', Province)
            setFieldValue('country', CountryName)
            setFieldValue('subBuilding', SubBuilding)
            setFieldValue('geocodedAddress', true)

            if (setErrors) {
              if (!ALLOWED_PROVINCES.includes(Province)) {
                setErrors({ address: ADRESS_ERROR_MESSAGE })
              }
            }
          })
        })
        window.addressComplete.load()
      }
    }

    if (loaded) {
      initAddressComplete()
    }

    return () => {
      mounted = false
    }
  }, [loaded])

  useEffect(() => {
    validateField('address')
    if (props.validateDob) validateField('dob')
  }, [values.address])

  const modifiedHandleChange = event => {
    setFieldValue('address', '')
    setFieldValue('street', '')
    setFieldValue('city', '')
    setFieldValue('postalCode', '')
    setFieldValue('province', '')
    setFieldValue('country', '')
    setFieldValue('subBuilding', '')
    setFieldValue('geocodedAddress', false)

    if (!values.startToEnterAddress && event.target.value !== '') {
      setFieldValue('startToEnterAddress', true)
    } else if (event.target.value === '') {
      setFieldValue('startToEnterAddress', false)
    }

    handleChange(event)
  }

  const modifiedHandleBlur = event => {
    if (!setFieldTouched) return
    handleBlur(event)
    setFieldTouched('address')
  }

  return (
    <div ref={el}>
      <Input {...props} onChange={modifiedHandleChange} onBlur={modifiedHandleBlur} />
    </div>
  )
}

AddressComplete.propTypes = {
  validateDob: bool
}

export default AddressComplete
