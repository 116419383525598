import React from 'react'
import styled from 'styled-components'
import { bool, shape, string } from 'prop-types'
import Icon from 'components/Icon'

import { fontSizes } from 'styles/typography'
import { successColor, errorColor } from 'styles/colors'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`
const Validationitem = styled.div`
  width: 50%;
  font-size: ${fontSizes.small};
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    display: inline-block;
  }
`

const PasswordValidationUI = ({ conditions, hasUserInteracted }) => {
  return (
    <Wrapper>
      {Object.entries(conditions).map(([key, value]) => {
        const color = !hasUserInteracted ? 'black' : value.isCompliant ? successColor : errorColor
        return (
          <Validationitem style={{ color }} key={key}>
            {hasUserInteracted &&
              (value.isCompliant ? <Icon icon={'checkmark-green'} small /> : <Icon icon={'cross-red'} small />)}
            <span>{value.message}</span>
          </Validationitem>
        )
      })}
    </Wrapper>
  )
}

PasswordValidationUI.propTypes = {
  conditions: shape({ message: string, isCompliant: bool }).isRequired,
  hasUserInteracted: bool
}

export default PasswordValidationUI
