import { css } from 'styled-components'

export default css`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 10px, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInUp {
    animation-name: fadeInUp;
  }

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translate3d(10px, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translate3d(-10px, 0, 0);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  .fadeInRight {
    animation-name: fadeInRight;
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
`
