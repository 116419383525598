import { api } from 'api'

export const getReferrersApi = ({ term }) => {
  return api.get({ endpoint: `/agents/referrers-autocomplete?term=${encodeURIComponent(term)}` })
}

export const getAgentAppDataApi = () => {
  return api.get({ endpoint: '/agents/agent-app-data' })
}

export const agentVerifyEmailApi = payload => {
  return api.post({ endpoint: '/agents/verify-email', body: payload })
}

export const agentUpdateUserApi = payload => {
  return api.post({ endpoint: '/agents/update-user', body: payload })
}

export const agentSourcesByChannelApi = payload => {
  return api.post({ endpoint: '/agents/sources-by-channel', body: payload })
}
