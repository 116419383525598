import React from 'react'
import styled, { css } from 'styled-components'
import { string, node, bool, oneOfType } from 'prop-types'
import { H1 } from 'styles/typography'
import { media } from 'styles/media'

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const normalPadding = css`
  padding: 40px 0;
  ${media.small`padding: 60px 0;`};
  ${media.medium`padding: 80px 0;`};
`

const narrowPadding = css`
  padding: 20px 0;
  ${media.small`padding: 30px 0;`};
  ${media.medium`padding: 40px 0;`};
`

const Title = styled.div`
  max-width: 530px;
  text-align: center;

  ${props => (props.hasSubtitle ? narrowPadding : normalPadding)}
`

const Section = ({ children, title, hasSubtitle, id, className }) => (
  <Container className={className} id={id}>
    {title && (
      <Title hasSubtitle={hasSubtitle}>
        <H1>{title}</H1>
      </Title>
    )}

    {children}
  </Container>
)

Section.propTypes = {
  children: node.isRequired,
  title: oneOfType([string, node]),
  hasSubtitle: bool,
  className: string,
  id: string
}

export default Section
