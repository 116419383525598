import styled from 'styled-components'
import { stripUnit } from 'polished'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { separatorColor } from 'styles/colors'

export default styled.hr`
  height: 1px;
  border-width: 0;
  background-color: ${separatorColor};
  margin: 0 ${spacing.mini};

  ${media.medium`
    margin: 0 ${stripUnit(spacing.mini) * 1.5 + 'px'};
  `};
`
