import React, { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import Input from 'components/FormComponents/Input/Input'
import Dropdown from 'components/FormComponents/Dropdown'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import PostalCodeInput from 'components/FormComponents/Address/PostalCodeInput'
import { CANADIAN_PROVINCES, ADRESS_ERROR_MESSAGE } from 'utils/constants'
import { isValid, isPostalCode, isPostalCodeValidWithProvince, isPostalCodeAllowed } from 'utils/validators'
import { bool } from 'prop-types'

const ManualAddressFieldset = ({ optional = false }) => {
  const { values, setFieldValue } = useFormikContext()
  const { startToEnterAddress, country, address, subBuilding, city, postalCode, province } = values

  const validateFilled = value => {
    if (optional) {
      if (startToEnterAddress && !isValid(value)) return 'If you want to enter your address, this field is required'
    } else {
      if (!isValid(value)) return 'Field is required'
    }
  }

  const validatePostalCode = value => {
    if (optional) {
      if (startToEnterAddress && !isValid(value)) return 'If you want to enter your address, this field is required'
      if (startToEnterAddress && !isPostalCode(value)) return 'Invalid postal code'
      if (startToEnterAddress && !isPostalCodeAllowed(value, province)) return ADRESS_ERROR_MESSAGE
      if (startToEnterAddress && !isPostalCodeValidWithProvince(value, province))
        return 'Invalid postal code based on province'
    } else {
      if (!isValid(value)) return 'Field is required'
      if (!isPostalCode(value)) return 'Invalid postal code'
      if (!isPostalCodeValidWithProvince(value, province)) return 'Invalid postal code based on province'
    }
  }

  const validateProvince = value => {
    if (optional) {
      if (startToEnterAddress && !isValid(value)) return 'If you want to enter your address, this field is required'
    } else {
      if (!isValid(value)) return 'Field is required'
    }
  }

  useEffect(() => {
    if (!country) setFieldValue('country', 'Canada')
  }, [values?.country])

  useEffect(() => {
    setFieldValue('postalCode', values?.postalCode?.toUpperCase())
  }, [values?.postalCode])

  useEffect(() => {
    if (isValid(address) || isValid(subBuilding) || isValid(city) || isValid(postalCode) || isValid(province)) {
      setFieldValue('startToEnterAddress', true)
    } else {
      setFieldValue('startToEnterAddress', false)
    }
  }, [address, subBuilding, city, postalCode, province])

  return (
    <>
      <Fieldset>
        <FieldsetItem xs="1-1" noPaddingTop noPaddingBottom>
          <Field name="address" validate={validateFilled}>
            {({ field, form: { errors, touched } }) => (
              <Input
                placeholder="123 Main St"
                label="Street Address"
                type="text"
                autoComplete="off"
                optional={!startToEnterAddress}
                {...field}
                meta={{
                  error: errors.address,
                  touched: touched.address
                }}
              />
            )}
          </Field>
        </FieldsetItem>
      </Fieldset>

      <Fieldset>
        <FieldsetItem xs="1-1" noPaddingTop noPaddingBottom>
          <Field name="subBuilding">
            {({ field, form: { errors, touched } }) => (
              <Input
                label="Apartment, suite, etc."
                placeholder="Apt 2"
                type="text"
                autoComplete="off"
                optional
                {...field}
                meta={{
                  error: errors.subBuilding,
                  touched: touched.subBuilding
                }}
              />
            )}
          </Field>
        </FieldsetItem>
      </Fieldset>

      <Fieldset>
        <FieldsetItem xs="1-1" sm="1-2" noPaddingTop noPaddingBottom>
          <Field name="city" validate={validateFilled}>
            {({ field, form: { errors, touched } }) => (
              <Input
                label="City"
                placeholder="Atlantis"
                type="text"
                autoComplete="off"
                optional={!startToEnterAddress}
                {...field}
                meta={{
                  error: errors.city,
                  touched: touched.city
                }}
              />
            )}
          </Field>
        </FieldsetItem>

        <FieldsetItem xs="1-1" sm="1-2" noPaddingTop noPaddingBottom>
          <Field name="postalCode" validate={validatePostalCode}>
            {({ field, form: { errors, touched } }) => (
              <PostalCodeInput
                label="Postal Code"
                name="postalCode"
                placeholder="A1A A1A"
                type="text"
                {...field}
                autoComplete="off"
                meta={{
                  error: errors.postalCode,
                  touched: touched.postalCode
                }}
                optional={!startToEnterAddress}
              />
            )}
          </Field>
        </FieldsetItem>
      </Fieldset>

      <Fieldset>
        <FieldsetItem xs="1-1" sm="1-2" noPaddingTop noPaddingBottom>
          <Field name="province" validate={validateProvince}>
            {({ field, form: { errors, touched } }) => (
              <Dropdown
                label="Province"
                placeholder="Select province"
                options={CANADIAN_PROVINCES}
                optional={!startToEnterAddress}
                {...field}
                meta={{
                  error: errors.province,
                  touched: touched.province
                }}
                full
              />
            )}
          </Field>
        </FieldsetItem>

        <FieldsetItem xs="1-1" sm="1-2" noPaddingTop noPaddingBottom>
          <Input label="Country" name="country" type="text" value="Canada" disabled />
        </FieldsetItem>
      </Fieldset>
    </>
  )
}

ManualAddressFieldset.propTypes = {
  optional: bool
}

export default ManualAddressFieldset
