import React from 'react'
import styled from 'styled-components'
import { textColor } from 'styles/colors'
import { fontSizes } from 'styles/typography'

const Consent = styled.p`
  color: ${textColor};
  text-align: justify;
  font-size: ${fontSizes.small};
`

const RegisterLabel = <Consent>By clicking “Register”, I agree to:</Consent>

const emailSubscription = (
  <Consent>
    Vuse sending me information on products, services and research opportunities relating to Vuse and other current and
    future brands distributed by Imperial Tobacco Company Ltd. that may be tailored to my preferences, purchases and app
    usage, including cart reminders. You can unsubscribe at any time. Tap the{' '}
    <a href={'https://www.vuse.com/ca/en/contact-us/'} target="_blank" rel="noreferrer">
      Contact Us
    </a>{' '}
    link for our contact information.
  </Consent>
)

const vusePlusOptInConsent = (
  <Consent>
    Join the Vuse Rewards loyalty program and I acknowledge that I have read and accepted the{' '}
    <a href={'https://www.vuse.com/ca/en/pages/vuse-rewards-terms-and-conditions/'} target="_blank" rel="noreferrer">
      Vuse Rewards Terms & Conditions
    </a>
    .
  </Consent>
)

const privacyPolicy = (
  <Consent>
    Your personal information will be used to provide me with Vuse products and services, remember my preferences,{' '}
    tailor my experience on the Vuse website, and provide me with content about Vuse products and services that may be of interest to me.{' '}
    For more information on how my information may be processed, I can consult the{' '}
    <a href={'https://www.vuse.com/ca/en/pages/privacy-policy/'} target="_blank" rel="noreferrer">
      Privacy Policy
    </a>
    . By creating an account, I acknowledge and agree to the processing of my personal information for these purposes.{' '}
    By registering, I acknowledge that I have read, understood and agree to the{' '}
    <a href={'https://www.vuse.com/ca/en/pages/terms-conditions/'} target="_blank" rel="noreferrer">
      terms and conditions
    </a>
    .
  </Consent>
)

export { emailSubscription, vusePlusOptInConsent, privacyPolicy, RegisterLabel }
