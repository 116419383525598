import React from 'react'
import { bool } from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import ManualAddressFieldset from 'components/FormComponents/Address/ManualAddressFieldset'
import AddressComplete from 'components/FormComponents/Address/AddressComplete'
import { placeholderColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const StyledButton = styled.button`
  text-decoration: underline;
`

const Hint = styled.p`
  color: ${placeholderColor};
  padding-top: ${spacing.mini};
  ${media.medium`
    padding-top: ${spacing.small};
  `};
`

const FullAddress = ({ optional = false, validateDob = false }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext()

  return (
    <>
      {!values.manualEntry && (
        <FieldsetItem xs="1-1">
          <AddressComplete
            type="text"
            label="Residential address"
            value={values.address}
            placeholder="Start typing..."
            name="address"
            meta={{
              error: errors.address,
              touched: touched.address
            }}
            optional={optional}
            validateDob={validateDob}
          />
          <Hint>
            Can&apos;t find your address?&nbsp;
            <StyledButton
              type="button"
              onClick={() => {
                setFieldValue('manualEntry', true)
              }}
            >
              Enter it manually instead
            </StyledButton>
          </Hint>
        </FieldsetItem>
      )}

      {values.manualEntry && (
        <>
          <ManualAddressFieldset optional={optional} />
          <FieldsetItem xs="1-1" noPaddingTop noPaddingBottom>
            <Hint>
              <StyledButton
                type="button"
                onClick={() => {
                  setFieldValue('manualEntry', false)
                }}
              >
                Autocomplete address
              </StyledButton>
            </Hint>
          </FieldsetItem>
        </>
      )}
    </>
  )
}

FullAddress.propTypes = {
  optional: bool,
  validateDob: bool
}

export default FullAddress
