import React, { createContext, useContext, useState, useEffect } from 'react'
import { node } from 'prop-types'

const AccountCreationContext = createContext()

export const useAccountCreation = () => {
  return useContext(AccountCreationContext)
}

// Time of inactivity for with we need agent PIN validation (in min)
const INACTIVITY_TIME = 45

export const AccountCreationProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [step, setStep] = useState(1)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [flow, setFlow] = useState('AccountCreation')
  const [lastStepChangeDate, setLastStepChangeDate] = useState(new Date())
  const [agentValidationRequired, setAgentValidationRequired] = useState(false)

  useEffect(() => {
    let flowTimer
    if (isTimerRunning) {
      flowTimer = setInterval(() => {
        // time in miliseconds
        setElapsedTime(time => time + 10)
      }, 10)
    }
    return () => clearInterval(flowTimer)
  }, [isTimerRunning])

  useEffect(() => {
    setLastStepChangeDate(new Date())
    if (step === 1) setAgentValidationRequired(false)
  }, [step])

  useEffect(() => {
    // If agentValidation is not yet required, start timer for inactivity check
    if (!agentValidationRequired) {
      const inactivityTimer = setInterval(() => {
        const currentTime = new Date()
        const timeDifference = currentTime - lastStepChangeDate

        if (timeDifference > INACTIVITY_TIME * 60 * 1000) {
          setAgentValidationRequired(true)
          clearInterval(inactivityTimer)
        }
      }, 1000)
      return () => clearInterval(inactivityTimer)
    }
  }, [step, lastStepChangeDate])

  const resetUser = () => setUser({})

  const previousStep = () => setStep(step - 1)
  const nextStep = () => setStep(step + 1)
  const resetStep = () => setStep(1)

  const startTimer = () => setIsTimerRunning(true)
  const stopTimer = () => setIsTimerRunning(false)
  const resetTimer = () => {
    setIsTimerRunning(false)
    setElapsedTime(0)
  }

  const value = {
    user,
    setUser,
    resetUser,
    step,
    previousStep,
    nextStep,
    resetStep,
    elapsedTime,
    startTimer,
    stopTimer,
    resetTimer,
    flow,
    setFlow,
    agentValidationRequired
  }

  return <AccountCreationContext.Provider value={value}>{children}</AccountCreationContext.Provider>
}

export const AccountCreationConsumer = AccountCreationContext.Consumer

AccountCreationProvider.propTypes = {
  children: node.isRequired
}
