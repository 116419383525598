import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ButtonCta from 'components/Button/ButtonCta'

const Container = styled.div`
  text-align: center;
`

const Error = ({ resetError }) => (
  <Container>
    <p>An error occured while processing your request.</p>

    <Link to="/" onClick={() => resetError(null)}>
      <ButtonCta theme="primary">Back</ButtonCta>
    </Link>
  </Container>
)

Error.propTypes = {
  resetError: func.isRequired
}

export default Error
