import React, { useEffect, useContext } from 'react'
import { Form, Formik } from 'formik'
import { useAccountCreation } from 'contexts/AccountCreation'
import { useLocalForage } from 'contexts/LocalForage'
import { SessionContext } from 'contexts/Session'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import FieldMessage from 'components/FormComponents/FieldMessage'
import ButtonCta from 'components/Button/ButtonCta'
import Checkbox from 'components/Checkbox'
import Separator from 'components/Separator'
import Referrer from 'components/AccountCreation/CreationFormThirdStep/Referrer'
import BundlesForm from 'components/AccountCreation/CreationFormThirdStep/BundlesForm'
import { getBundle } from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/Bundle'
import { AGENT_CONSENT_TEXT, USER_TYPE } from 'utils/constants'
import { AGENT_APP_VERSION } from 'utils/version'
import { isUserObjValid } from 'utils/validators'
import { validationSchema, getInitialValues } from 'components/AccountCreation/CreationFormThirdStep/formValidation'
import PreferredBrand from './PreferredBrand'
import { createOrUpdateUser, USER_ACTIONS } from 'api/users'
import toast from 'react-hot-toast'

const CreationFormThirdStep = () => {
  const { user, resetUser, resetStep, resetTimer, elapsedTime } = useAccountCreation()
  const { isOnline } = useContext(SessionContext)
  const { getStoredItem } = useLocalForage()

  const handleThirdStepSubmit = async (values, { resetForm }) => {
    // Filter bundles to keep only bundle with a device or flavours
    const bundlesGiven = values.bundlesGiven.map(bundle => getBundle(bundle)).filter(bundle => bundle)

    const appBuildId = process.env.BUILD_ID
    const appCommitRef = process.env.COMMIT_REF

    const newUser = {
      ...user,
      ...values,
      bundlesGiven,
      agentAppTracking: {
        elapsedTime,
        appBuildId,
        appCommitRef,
        appVersion: AGENT_APP_VERSION,
        userType: USER_TYPE.new
      }
    }

    if (!isUserObjValid(newUser)) {
      toast.error('An error occurred please try again.')
      resetStep()
      return
    }

    const agentJWT = await getStoredItem('agentJWT')
    const requestBody = { user: newUser, agentJWT, action: USER_ACTIONS.create }

    try {
      const response = await createOrUpdateUser(requestBody)
      resetForm()
      resetUser()
      resetStep()
      resetTimer()
      if (response.success) {
        toast.success('User has been created')
      } else {
        toast.error('Error occurred while submitting user.')
      }
      return response
    } catch (error) {
      console.log(error)
      toast.error('An error occurred please try again.')
      resetStep()
      return error
    }
  }

  useEffect(() => {
    // Extra check to test if the user object is well formed
    if (!isUserObjValid(user)) {
      toast.error('An error occurred please try again.')
      resetStep()
    }
  }, [user])

  return (
    <Section title={'Help us better understand our consumers'}>
      <Wrapper>
        <Formik
          initialValues={getInitialValues(user)}
          validationSchema={validationSchema}
          onSubmit={handleThirdStepSubmit}
          validateOnChange
        >
          {({ values, handleChange, isSubmitting }) => {
            return (
              <Form>
                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <Checkbox
                      label="Consumer is of legal age"
                      titleLabel
                      name="agentConsent"
                      onChange={handleChange}
                      checked={values.agentConsent}
                    />
                  </FieldsetItem>

                  <FieldsetItem xs="1-1" noPaddingTop>
                    <p>{AGENT_CONSENT_TEXT}</p>
                  </FieldsetItem>
                </Fieldset>

                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <Referrer label="Was the consumer Invited by a friend?" name="userRefererId" optional={2} />
                  </FieldsetItem>
                </Fieldset>

                <BundlesForm name="bundlesGiven" />

                <PreferredBrand />

                <Separator />

                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <ButtonCta type="submit" disabled={!values.agentConsent || !isOnline} isLoading={isSubmitting || !isOnline}>
                      Confirm and submit
                    </ButtonCta>
                  </FieldsetItem>
                  <FieldsetItem xs="1-1">
                    {!isOnline && <FieldMessage align={'center'}>Internet connection problems</FieldMessage>}
                  </FieldsetItem>
                </Fieldset>
              </Form>
            )
          }}
        </Formik>
      </Wrapper>
    </Section>
  )
}

export default CreationFormThirdStep
