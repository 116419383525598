import React from 'react'
import { useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import { string, bool, node, func } from 'prop-types'
import * as spacing from 'styles/spacing'
import { white, backgroundColor, errorColor } from 'styles/colors'
import { animationTime, animationCurve } from 'styles/global'
import { fontWeights } from 'styles/typography'
import Icon from 'components/Icon'
import Label from 'components/FormComponents/Label'

export const boxSpacing = `${spacing.medium}`
export const boxSize = '22px'

const Container = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  & + & {
    margin-top: ${spacing.medium};
  }
`

const Control = styled.span`
  margin-right: ${boxSpacing};
  flex-shrink: 0;
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const Indicator = styled.div`
  width: ${boxSize};
  height: ${boxSize}
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex-shrink: 0;
  background-color: ${white};
  border: 2px solid ${backgroundColor};
  transition: background-color ${animationTime} ${animationCurve}, border-color ${animationTime} ${animationCurve};

  ${Input}:checked + & {
    background-color: ${backgroundColor};
    border-color: ${backgroundColor};
  }

  ${props =>
    props.error &&
    css`
      border: 2px solid ${errorColor};
    `}
`

const Check = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity ${animationTime} ${animationCurve}, transform ${animationTime} ${animationCurve};

  ${Input}:checked ~ ${Indicator} & {
    opacity: 1; /* Show icon when checkbox is checked */
    transform: none;
  }
`

const CheckboxLabel = styled.span`
  text-align: left;
  margin-top: -2px; /* Align text with checkbox */
  font-weight: ${fontWeights.semibold};
`

const Checkbox = ({ label, titleLabel, checked, error, onChange, name }) => {
  const { setTouched, touched } = useFormikContext()

  const handleChange = e => {
    if (touched[name] === undefined) setTouched({ ...touched, [name]: true })
    onChange(e)
  }

  return (
    <Container>
      <Control>
        <Input type="checkbox" checked={checked} onChange={handleChange} name={name} />
        <Indicator error={error}>
          <Check icon="checkmark-small" white />
        </Indicator>
      </Control>

      {label && <>{titleLabel ? <Label>{label}</Label> : <CheckboxLabel>{label}</CheckboxLabel>}</>}
    </Container>
  )
}

Checkbox.propTypes = {
  label: node,
  titleLabel: bool,
  checked: bool,
  error: bool,
  onChange: func,
  name: string
}

export default Checkbox
