import * as yup from 'yup'

export const bundleInitShape = {
  deviceId: '',
  flavourIds: ['']
}

export const getInitialValues = user => {
  return {
    agentConsent: user.agentConsent || false,
    userRefererId: user.userRefererId || '',
    bundlesGiven: user.bundlesGiven || [bundleInitShape],
    preferredBrandVapeValue: '',
    preferredBrandCigaretteValue: ''
  }
}

export const validationSchema = props => {
  return yup.lazy(values => {
    return yup.object().shape({
      agentConsent: yup.bool().oneOf([true], 'Agent consent is required')
    })
  })
}
