import React, { createContext, useContext, useState } from 'react'
import { node } from 'prop-types'
import localforage from 'lib/localforage'

const LocalForageContext = createContext()

export const useLocalForage = () => {
  return useContext(LocalForageContext)
}

export const LocalForageProvider = ({ children }) => {
  const [localForageError, setLocalForageError] = useState(null)

  const getStoredItem = async key => {
    try {
      const item = await localforage.getItem(key)
      return item
    } catch (err) {
      console.log(err)
      setLocalForageError(err)
    }
  }

  const setStoredItem = async (key, value) => {
    try {
      const item = await localforage.setItem(key, value)
      return item
    } catch (err) {
      console.log(err)
      setLocalForageError(err)
    }
  }

  const removeStoredItem = async key => {
    try {
      const item = await localforage.removeItem(key)
      return item
    } catch (err) {
      console.log(err)
      setLocalForageError(err)
    }
  }

  const removeStoredItems = async keys => {
    keys.forEach(key => {
      try {
        localforage.removeItem(key)
      } catch (err) {
        console.log(err)
        setLocalForageError(err)
      }
    })
  }

  const clearAgentAppData = async () => {
    try {
      removeStoredItems([
        'agentSourceOptions',
        'deviceGivenOptions',
        'flavoursGivenOptions',
        'preferredBrandCigarette',
        'preferredBrandVape',
        'storageExpiryDate',
        'isDataFetched'
      ])
    } catch (err) {
      console.log(err)
      setLocalForageError(err)
    }
  }

  const clearStorage = async () => {
    try {
      await localforage.clear()
    } catch (err) {
      console.log(err)
      setLocalForageError(err)
    }
  }

  const value = {
    getStoredItem,
    setStoredItem,
    removeStoredItem,
    removeStoredItems,
    clearAgentAppData,
    clearStorage,
    localForageError
  }

  return <LocalForageContext.Provider value={value}>{children}</LocalForageContext.Provider>
}

LocalForageProvider.propTypes = {
  children: node.isRequired
}
