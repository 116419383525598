import React, { useCallback, useEffect, useRef, useState } from 'react'
import { bool, func, number, oneOfType, string } from 'prop-types'
import styled, { css } from 'styled-components'
import { inputBackColor, hoverInputColor, placeholderColor } from 'styles/colors'
import { field } from 'styles/form'
import Label from 'components/FormComponents/Label'
import { DropdownIcon } from 'components/FormComponents/Dropdown'
import Spinner from 'components/Spinner'
import FieldMessage from 'src/components/FormComponents/FieldMessage'

const Field = styled.div`
  position: relative;
`

const Input = styled.input`
  ${field}
`

const Suggestions = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  margin: 0 12px;
  overflow-y: auto;
  background-color: ${inputBackColor};
  position: absolute;
  z-index: 1;
  left: 0;
  top: auto;
  right: 0;
  max-height: 300px;
`

const activeSuggestion = css`
  background-color: ${hoverInputColor};
  cursor: pointer;
`

const Suggestion = styled.li`

  padding: 5px 20px;
  color: ${placeholderColor}
  &:hover {
    ${activeSuggestion}
  }

  ${props => props.active && activeSuggestion}
`

const NotFound = styled.div`
  color: ${placeholderColor};
  padding: 5px;
`

const AutoComplete = ({
  filterSuggestions,
  onChange,
  onSelect,
  label,
  placeholder = 'Type to search',
  optional,
  dropdown
}) => {
  const suggestionsRef = useRef()
  const inputRef = useRef()
  const [loading, setLoading] = useState(false)
  const [activeSuggestion, setActiveSuggestion] = useState(0)
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [suggestionClicked, setSuggestionClicked] = useState(false)
  const [userInput, setUserInput] = useState('')

  let timeout
  const debounce = (func, delay) => {
    clearTimeout(timeout)
    timeout = setTimeout(func, delay)
  }

  const searchSuggestions = async () => {
    setLoading(true)

    const term = inputRef.current.value
    const filteredSuggestions = await filterSuggestions(term)

    setActiveSuggestion(0)
    setFilteredSuggestions(filteredSuggestions)
    setShowSuggestions(true)

    setLoading(false)
  }

  const onInputChange = async e => {
    setSuggestionClicked(false)
    const term = e.currentTarget.value
    setUserInput(term)
    if (onChange) onChange()
  }

  const onSuggestionClicked = suggestion => {
    setSuggestionClicked(true)
    setActiveSuggestion(0)
    setFilteredSuggestions([])
    setShowSuggestions(false)
    setUserInput(suggestion.label)

    onSelect(filteredSuggestions.find(sug => sug.value === suggestion.value))
  }

  const onKeyDown = e => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setActiveSuggestion(0)
      setShowSuggestions(false)
      setUserInput(filteredSuggestions[activeSuggestion].label)
      onSelect(filteredSuggestions[activeSuggestion])
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) return
      setActiveSuggestion(activeSuggestion - 1)
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) return
      setActiveSuggestion(activeSuggestion + 1)
    }
  }

  const setSuggestionsRef = useCallback(
    node => {
      suggestionsRef.current = node
    },
    [showSuggestions]
  )

  useEffect(() => {
    const handleOutsideClick = event => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
          setShowSuggestions(false)
        }
      }
    }

    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener('input', () => {
        debounce(searchSuggestions, 500)
      })
    }

    document.addEventListener('click', handleOutsideClick)
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [inputRef])

  const onClickInputHandle = async event => {
    const filteredSuggestions = await filterSuggestions()
    setActiveSuggestion(0)
    setFilteredSuggestions(filteredSuggestions)
    setShowSuggestions(true)
    setLoading(false)
  }

  return (
    <>
      {label && <Label optional={optional}>{label}</Label>}

      <Field>
        <Input
          onClick={onClickInputHandle}
          type="text"
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder={placeholder}
          ref={inputRef}
        />
        {loading ? <Spinner position="right" /> : dropdown && <DropdownIcon icon="dropdown" />}
      </Field>

      {showSuggestions && filteredSuggestions && (
        <>
          {filteredSuggestions.length ? (
            <Suggestions ref={setSuggestionsRef}>
              {filteredSuggestions.map((suggestion, index) => {
                return (
                  <Suggestion
                    active={index === activeSuggestion}
                    key={suggestion.value}
                    onClick={() => onSuggestionClicked(suggestion)}
                  >
                    {suggestion.label}
                  </Suggestion>
                )
              })}
            </Suggestions>
          ) : (
            <NotFound>
              <em>No results</em>
            </NotFound>
          )}
        </>
      )}

      {inputRef.current?.value !== '' && !suggestionClicked && (
        <FieldMessage>If you want to save this field, you must select a value in the dropdown</FieldMessage>
      )}
    </>
  )
}

AutoComplete.propTypes = {
  filterSuggestions: func.isRequired,
  onChange: func,
  onSelect: func.isRequired,
  label: string,
  placeholder: string,
  optional: oneOfType([bool, number]),
  dropdown: bool
}

export default AutoComplete
